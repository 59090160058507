import { Alert } from "@mui/material";
import React, { ErrorInfo } from "react";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

/**
 * ErrorBoundary Component
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  /**
   *  Error Boundry State
   *  @param props - The properties of the ErrorBoundary component.
   */
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  /**
   * This gets the deriver child component error and updates the state.
   * @param error - The error that was thrown.
   * @returns The state to update.
   */
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  /**
   * This lifecycle is invoked after an error has been thrown by a descendant component.
   * This can then log the error to the proper destination, wherever that may be in future.
   * @param error - The error that was thrown.
   * @param errorInfo - Information about the error.
   */
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can log the error or send it to an error reporting service
    // Cahnge this to actually handle the error no just return to console
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  /**
   * @returns The rendered component.
   */
  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can customize the error message or render a fallback UI here
      return <Alert severity="error">Er is iets misgegaan. Sluit dit venster en probeer het opnieuw.</Alert>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
