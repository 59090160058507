import React, { FC } from "react";

// MSAL Imports
import { IPublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

// Page Imports
import Home from "./home/Home";
import Authenticator from "./home/Authenticator";

// Material Imports
import { CssBaseline } from "@mui/material";

// Context Imports
import { ExternalStorageContextProvider } from "../contexts/ChangeTracking/ExternalStorageContext";
import { NLUContextProvider } from "../contexts/NLU/NLUContext";
import { LocalStorageContextProvider } from "../contexts/ChangeTracking/LocalStorageContext";
import { ErrorContextProvider } from "../contexts/ErrorContext";
import { IntentGroupContextProvider } from "../contexts/NLU/IntentGroupContext";

import Webchat from "../components/Webchat";
import { ClientContextProvider } from "../contexts/ClientContext";

interface AppProps {
  pca: IPublicClientApplication;
}

const webchatScript = `${process.env.REACT_APP_CHAT_URL!}/webchat_builder.bundle.js`;

/**
 * Renders the application
 * @param pca - The public client application
 * @returns A React component representing the application
 */
const App: FC<AppProps> = ({ pca }) => (
  <MsalProvider instance={pca}>
    <CssBaseline />
    <AuthenticatedTemplate>
      <ClientContextProvider>
      <Webchat src={webchatScript} />
        <ErrorContextProvider>
          <NLUContextProvider>
            <IntentGroupContextProvider>
              <ExternalStorageContextProvider>
                <LocalStorageContextProvider>
                  <Home />
                </LocalStorageContextProvider>
              </ExternalStorageContextProvider>
            </IntentGroupContextProvider>
          </NLUContextProvider>
        </ErrorContextProvider>
      </ClientContextProvider>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Authenticator />
    </UnauthenticatedTemplate>
  </MsalProvider>
);

export default App;
