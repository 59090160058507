import React, { FC, useReducer, ReactNode, useContext, createContext, Dispatch, useEffect, useMemo } from "react";

// Data Imports
import { StorageEnvironment } from "../../API/StorageInteraction";

// Component Imports
import { ChangeBucket, TrackedChange, changeBucketTemplateArray } from "../../models/ChangeTracking";
import { deepCopy } from "../../helpers/deepCopy";
import {
  getBuckets,
  LiveChanges,
  UpdateBucketWithChange,
  UpdateBucketWithChanges,
} from "../../API/ChangeManagementInteraction";

// Region container context

// Builds the context that provides the producer context
const ExternalStorageContext = createContext<externalStorageContextAttributes | null>(null);

// Builds the context that provides the function that lets components dispatch actions
const ExternalStorageDispatchContext = createContext<Dispatch<externalStorageReducerAction> | null>(null);

// Initial producer state provided to the reducer in the provider
const initialExternalStorageContext: externalStorageContextAttributes = {
  changeBuckets: deepCopy(changeBucketTemplateArray),
};

enum ExternalStorageActionType {
  updateAllBuckets,
  updateSingleBucket,
}

interface ExternalStorageContextProviderProps {
  children: ReactNode | Array<ReactNode> | null;
}

export type externalStorageContextAttributes = {
  changeBuckets: Array<ChangeBucket>;
  getChanges?: (callback?: (changesForCallback: Array<ChangeBucket>) => Promise<void>) => Promise<void>;
  updateChange?: (update: TrackedChange) => void;
  updateChanges?: (update: ChangeBucket) => void;
  saveChanges?: () => Promise<void>;
};

/**
 * Provides the producer context to the application
 * @param children - The children of the container
 * @returns  A provider element
 */
export const ExternalStorageContextProvider: FC<ExternalStorageContextProviderProps> = ({ children }) => {
  const [context, dispatch] = useReducer(externalStorageReducer, initialExternalStorageContext);

  useEffect(() => {
    // Retrieve the existing saved changes
    void getChanges();
  }, []);

  /**
   * Get the persistent changes for staging to production from the storage
   */
  const getChanges = async (): Promise<void> => {
    // Get the change bucket array
    const changes = await getBuckets(StorageEnvironment.Staging);

    // Build a dispatch action
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.updateAllBuckets,
      payload: changes,
    };

    dispatch(action);
  };

  /**
   * Context function that updates a tracked change inside it's corresponding bucket
   * (may not be intended behavior in it's current state)
   * @param update - Generated by a component to be put into context
   */
  const updateChange = (update: TrackedChange): void => {
    // Get the bucket that needs to be updated
    const bucketToUpdate: ChangeBucket = context.changeBuckets.find(
      (bucket: ChangeBucket) => bucket.key === update.containerName,
    )!;

    // Create the updated bucket with updated tracked change
    const updatedBucket: ChangeBucket = {
      ...bucketToUpdate,
      changes: bucketToUpdate.changes.map((change) => (change.key === update.key ? update : change)),
    };

    // Update the change tracking data
    void UpdateBucketWithChange(StorageEnvironment.Staging, update);

    // Build a dispatch action
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.updateSingleBucket,
      payload: updatedBucket,
    };

    dispatch(action);
  };

  /**
   * Context function that updates a corresponding bucket
   * (may not be intended behavior in it's current state)
   * @param update - Generated by a component to be put into context
   */
  const updateChanges = (update: ChangeBucket): void => {
    // Get the bucket that needs to be updated
    const bucketToUpdate: ChangeBucket = context.changeBuckets.find(
      (bucket: ChangeBucket) => bucket.key === update.key,
    )!;

    // Create the updated bucket with updated tracked changes
    const updatedBucket = { ...bucketToUpdate, changes: update.changes };

    // Update the change tracking data
    void UpdateBucketWithChanges(StorageEnvironment.Staging, update.changes);

    // Build a dispatch action
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.updateSingleBucket,
      payload: updatedBucket,
    };

    dispatch(action);
  };

  /**
   * Initiate the process of saving changes to the staging in the backend.
   */
  const saveChanges = async (): Promise<void> => {
    // Save the changes with the buckets of the staging context
    const response: Array<ChangeBucket> = await LiveChanges();

    // Build a dispatch action
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.updateAllBuckets,
      payload: response,
    };

    dispatch(action);
  };

  // Build the context
  const _context = useMemo(
    () => ({
      changeBuckets: context.changeBuckets,
      getChanges,
      updateChange,
      updateChanges,
      saveChanges,
    }),
    [context.changeBuckets],
  );

  return (
    <ExternalStorageContext.Provider value={_context}>
      <ExternalStorageDispatchContext.Provider value={dispatch}>{children}</ExternalStorageDispatchContext.Provider>
    </ExternalStorageContext.Provider>
  );
};

/**
 * Provides the container context
 * @returns A context
 */
export const useExternalStorageContext = (): externalStorageContextAttributes | null =>
  useContext(ExternalStorageContext);

type externalStorageReducerAction = {
  type: ExternalStorageActionType;
  payload: ChangeBucket | Array<ChangeBucket>;
};

/**
 * External storage context reducer
 * @param context - The state to be updated
 * @param action - The action to be executed by the reducer
 * @returns Updated state
 */
const externalStorageReducer = (
  context: externalStorageContextAttributes,
  action: externalStorageReducerAction,
): externalStorageContextAttributes => {
  switch (action.type) {
    case ExternalStorageActionType.updateSingleBucket: {
      const updatedBucket = action.payload as ChangeBucket;
      const changeBuckets: Array<ChangeBucket> = context.changeBuckets.map((bucket: ChangeBucket) =>
        bucket.key === updatedBucket.key ? updatedBucket : bucket,
      );

      return { ...context, changeBuckets };
    }
    case ExternalStorageActionType.updateAllBuckets: {
      const changeBuckets = action.payload as Array<ChangeBucket>;

      return { ...context, changeBuckets };
    }
  }
};

// #endregion
