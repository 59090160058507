import React, { FC } from "react";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { Language } from "../../models/Language";

interface LanguageSelectProps {
  handleChange: (value: string) => void;
  languages: Array<Language>;
  label: string;
  selectedValue: string;
}

/**
 * LanguageSelect Component
 * @param handleChange - The function to handle the change of the selected value.
 * @param languages - The list of languages.
 * @param label - The label of the select.
 * @param selectedValue - The selected value.
 * @returns A LanguageSelect component.
 */
const LanguageSelect: FC<LanguageSelectProps> = ({ handleChange, languages, label, selectedValue }) =>
  languages.length === 0 ? (
    <></>
  ) : (
    <Box mb={2}>
      <InputLabel data-testid="input-label">{label}</InputLabel>
      <Select
        inputProps={{ "data-testid": "language-select" }}
        value={selectedValue}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      >
        {languages.map((el) => (
          <MenuItem value={el.key} key={el.key}>
            {el.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

export default LanguageSelect;
