export interface TopDeskItem {
  id: string;
  name: string;
}

export interface TopDeskSubcategory {
  id: string;
  name: string;
  category: TopDeskItem;
}

export const enum ITSMPlatformTexts {
  TOPdesk = "TopDesk",
  Salesforce = "Salesforce",
}

export enum ITSMPlatform {
  TOPdesk = 0,
  Salesforce = 1,
}

export type TOPDeskInformation = {
  briefDescription: string;
  request: string;
  category: TopDeskItem | null;
  subcategory: TopDeskSubcategory | null;
  callType: TopDeskItem | null;
  impact: TopDeskItem | null;
  urgency: TopDeskItem | null;
  priority: TopDeskItem | null;
  processingStatus: TopDeskItem | null;
  entryType: TopDeskItem | null;
};

export type SalesforceInformation = {
  type: string;
  status: string;
  origin: string;
  subject: string;
  priority: string;
  description: string;
};

export type ITSMDescriptionQuesion = {
  id: string;
  questionHeader: string;
  question: string;
  answer: string;
  nextStep: string;
};
