import React, { FC, ReactElement } from "react";
import { Box, Tooltip, TooltipProps } from "@mui/material";

export interface CustomToolTipProps {
  title: string;
  child: ReactElement;
  placement?: TooltipProps["placement"];
}

/**
 * A custom tooltip component that wraps the child component in a div to fix a ref issue.
 * @param title - The title to show in the tooltip
 * @param child - The child to show the tooltip for
 * @param placement -  The placement of the tooltip (default: top)
 * @returns - A tooltip with the given title and child
 */
const CustomToolTip: FC<CustomToolTipProps> = ({ title, child, placement = "top" }) => (
  <Tooltip title={title} placement={placement}>
    <Box>{child}</Box>
  </Tooltip>
);

export default CustomToolTip;
