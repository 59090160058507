import React, { FC, useState, useEffect, useRef } from "react";
import { dialog, Dialog as DialogModel } from "../../../../models/Dialogs";

// Material Imports
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "@mui/icons-material/Key";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";

interface CreateNewDialogProps {
  executable: (update: any) => Promise<void>;
}

/**
 * A functional component representing the 'CreateHighlightDialog'.
 * @param executable - Function to execute when the dialog content is submitted.
 * @param language - The language for the 'CreateHighlightDialog' component.
 * @returns JSX element representing the 'CreateHighlightDialog'.
 */
const CreateNewDialog: FC<CreateNewDialogProps> = ({ executable }) => {
  const [newDialog, setNewDialog] = useState<DialogModel>({
    ...dialog,
  });
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");

  /**
   * Handles opening the dialog with the specified scroll type.
   * @param scrollType - The scroll type for the dialog (e.g., 'paper', 'body', 'paperScrollBody', etc.).
   */
  const handleOpen = (scrollType: DialogProps["scroll"]) => () => {
    setScroll(scrollType);
    setOpen(true);
  };

  /**
   * Handles closing the dialog and resetting the 'newHighlight' state to its initial values.
   */
  const handleClose = (): void => {
    if (["", null, undefined].includes(newDialog.key)) {
      setNewDialog({ ...dialog });
    }
    setOpen(false);
  };

  /**
   * Ref to the description element for accessibility purposes.
   */
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    } else {
      return () => {
        setNewDialog(dialog);
      };
    }
  }, [open]);

  /**
   * Handles the change event when the input values are changed.
   * Updates the corresponding properties in the 'newHighlight' state based on the event target's 'name'.
   * @param event - The React change event representing the change in the input value.
   */
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const updatedNewDialog: DialogModel = { ...newDialog };

    if (name === "DialogName") {
      updatedNewDialog.name = value;
    }

    setNewDialog(updatedNewDialog);
  };

  return (
    <>
      <Button onClick={handleOpen("paper")}>
        <AddIcon />
      </Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll}>
        <DialogTitle>Nieuwe Dialoog</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="new-dialog-description" borderBottom={"1px solid #dee2e6"} marginBottom={"10px"}>
            Vul de benodigde informatie in om een nieuwe dialoog toe te voegen aan de chatbot.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="input-key-wIcon"
                label="Naam Dialoog"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                fullWidth
                value={newDialog.name}
                onChange={onInputChange}
                name="DialogName"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              executable({ ...newDialog }).catch((error) => {
                throw new Error(error);
              });
              handleClose();
            }}
            disabled={newDialog.name.length <= 0}
          >
            Opslaan
          </Button>
          <Button onClick={handleClose}>Annuleren</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateNewDialog;
