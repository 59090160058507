import React, { FC } from "react";
import ReactCountryFlag from "react-country-flag";

/**
 * @returns A flag icon for the United Kingdom
 */
export const GBFlagIcon: FC = () => <FlagIcon countryCode="GB" />;

/**
 * @returns A flag icon for the Netherlands
 */
export const NLFlagIcon: FC = () => <FlagIcon countryCode="NL" />;

/**
 * @returns A flag icon for France
 */
export const FRFlagIcon: FC = () => <FlagIcon countryCode="FR" />;

/**
 * @returns A flag icon for Germany
 */
export const DEFlagIcon: FC = () => <FlagIcon countryCode="DE" />;

/**
 * @param languageKey - The language key to get the flag icon for.
 * See https://github.com/danalloway/react-country-flag/blob/main/example/countries.ts for all available keys.
 * @returns A flag icon for the given language key
 */
const FlagIcon: FC<{ countryCode: string }> = ({ countryCode }) => (
  <ReactCountryFlag
    countryCode={countryCode}
    svg
    style={{
      width: "2em",
      height: "2em",
    }}
  />
);
