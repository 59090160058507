import React, { FC, useReducer, ReactNode, useContext, createContext, Dispatch, useMemo } from "react";

// Data Imports
import { TrackedChange, ChangeBucket, changeBucketTemplateArray, ChangeType } from "../../models/ChangeTracking";
import { deepCopy } from "../../helpers/deepCopy";

import { useErrorContext } from "../ErrorContext";
import { StageChanges } from "../../API/ChangeManagementInteraction";

// Builds the context that provides the local storage information
const LocalStorageContext = createContext<localStorageContextAttributes | null>(null);

// Builds the context that provides the function that lets components dispatch actions
const LocalStorageDispatchContext = createContext<Dispatch<localStorageReducerAction> | null>(null);

// Initial local storage state provided to the reducer in the provider
const initialLocalStorageContext: localStorageContextAttributes = {
  changeBuckets: deepCopy(changeBucketTemplateArray),
};

/**
 * Enum that contains all possible actions
 */
enum LocalStorageActionType {
  updateAllBuckets,
  updateSingleBucket,
}

/**
 * Interfaces the children property to the context provider
 */
interface LocalStorageContextProviderProps {
  children: ReactNode | Array<ReactNode> | null;
}

/**
 * Interfaces the context values and functions with the provider component
 */
export type localStorageContextAttributes = {
  changeBuckets: Array<ChangeBucket>;
  createKey?: (containerName: string, key: string) => string;
  getChangeByKey?: (changeId: string, containerName: string) => TrackedChange | undefined;
  trackChange?: (update: TrackedChange) => void;
  updateChange?: (update: TrackedChange) => void;
  updateChanges?: (update: ChangeBucket) => void;
  untrackChange?: (update: TrackedChange) => void;
  trackedChangeExists?: (update: TrackedChange) => boolean;
  saveChanges?: () => Promise<void>;
};

/**
 * Provides the local storage context to the application
 * @param children - The children of a provider
 * @returns a provider element
 */
export const LocalStorageContextProvider: FC<LocalStorageContextProviderProps> = ({ children }) => {
  const [context, dispatch] = useReducer(localStorageReducer, initialLocalStorageContext);

  const errorContext = useErrorContext();

  /** Create key */
  const createKey = (containerName: string, changeKey: string): string => `change-${containerName}-${changeKey}`;

  /**
   * Context function that allows an item (if it exists) to be retrieved from a change bucket
   */
  const getChangeByKey = (changeKey: string, containerName: string): TrackedChange | undefined => {
    try {
      // Get the change bucket
      const bucket = context.changeBuckets.find((bucket: ChangeBucket) => bucket.key === containerName);
      if (bucket === undefined) return;

      const change = bucket.changes.find((change) => change.key === changeKey);

      return change;
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${getChangeByKey.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that adds a tracked change to it's corresponding bucket
   * @param update - Generated by a component to be put into context
   */
  const trackChange = (update: TrackedChange): void => {
    try {
      // Get the change bucket
      const bucket = context.changeBuckets.find((bucket: ChangeBucket) => bucket.key === update.containerName);

      if (bucket === undefined) {
        errorContext?.createError!(
          new Error(`LocalStorageContext Error | ${trackChange.name} - ERROR: Bucket not found`),
        );

        return;
      }

      // Add the change to the bucket
      bucket.changes.push(update);

      // Build a dispatch action
      const action: localStorageReducerAction = {
        type: LocalStorageActionType.updateSingleBucket,
        payload: bucket,
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${trackChange.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that updates a tracked change inside it's corresponding bucket
   * (may not be intended behavior in it's current state)
   * @param update - Generated by a component to be put into context
   */
  const updateChange = (update: TrackedChange): void => {
    try {
      // Get the bucket that needs to be updated
      const bucketToUpdate: ChangeBucket = context.changeBuckets.find(
        (bucket: ChangeBucket) => bucket.key === update.containerName,
      )!;

      const newChanges = bucketToUpdate.changes.map((change) => {
        if (change.key === update.key) {
          // If update given but tracked change is new, keep change type as new
          if (change.changeType === ChangeType.New && update.changeType === ChangeType.Update) {
            return { ...update, changeType: ChangeType.New };
          }

          return update;
        }

        return change;
      });

      // Create the updated bucket with updated tracked change
      const updatedBucket: ChangeBucket = {
        ...bucketToUpdate,
        changes: newChanges,
      };

      const action: localStorageReducerAction = {
        type: LocalStorageActionType.updateSingleBucket,
        payload: updatedBucket,
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${updateChange.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that updates a corresponding bucket
   * (may not be intended behavior in it's current state)
   * @param update - Generated by a component to be put into context
   */
  const updateChanges = (update: ChangeBucket): void => {
    // Get the bucket that needs to be updated
    const bucketToUpdate = context.changeBuckets.find((bucket: ChangeBucket) => bucket.key === update.key);

    if (bucketToUpdate === undefined) {
      errorContext?.createError!(
        new Error(`LocalStorageContext Error | ${trackChange.name} - ERROR: Bucket not found`),
      );

      return;
    }

    // Create the updated bucket with updated tracked changes
    const updatedBucket = { ...bucketToUpdate, changes: update.changes };

    // Build a dispatch action
    const action: localStorageReducerAction = {
      type: LocalStorageActionType.updateSingleBucket,
      payload: updatedBucket,
    };

    dispatch(action);
  };

  /**
   * Context function that removes a tracked change from it's corresponding bucket
   * @param update - Generated by a component to be put into context
   */
  const untrackChange = (update: TrackedChange): void => {
    try {
      // Get the change bucket
      const bucket = context.changeBuckets.find((bucket: ChangeBucket) => bucket.key === update.containerName);

      if (bucket === undefined) {
        errorContext?.createError!(
          new Error(`LocalStorageContext Error | ${trackChange.name} - ERROR: Bucket not found`),
        );

        return;
      }

      // Remove the change from the bucket
      const updatedBucket = { ...bucket, changes: bucket.changes.filter((change) => change.key !== update.key) };

      // Build a dispatch action
      const action: localStorageReducerAction = {
        type: LocalStorageActionType.updateSingleBucket,
        payload: updatedBucket,
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${untrackChange.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that allows for checking whether the change to track already exists.
   * Keeping in mind that every update has a unique id, we check by update's key.
   * @param key - the key of the item related to the change.
   * @param containerName - the related bucket to find by it's container name.
   */
  const trackedChangeExists = (update: TrackedChange): boolean => {
    try {
      // Get the change bucket
      const bucket = context.changeBuckets.find((bucket: ChangeBucket) => bucket.key === update.containerName);

      if (bucket === undefined) return false;

      const changeExists = bucket.changes.find((change) => change.key === update.key);

      return changeExists !== undefined;
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${trackedChangeExists.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);

      return false;
    }
  };

  /**
   * Initiate the process of saving changes to the staging in the backend.
   */
  const saveChanges = async (): Promise<void> => {
    try {
      // Save the changes with the buckets of the local context
      const response: Array<ChangeBucket> = await StageChanges(context.changeBuckets);

      // Build a dispatch action
      const action: localStorageReducerAction = {
        type: LocalStorageActionType.updateAllBuckets,
        payload: response,
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${saveChanges.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  const _context: localStorageContextAttributes = useMemo(
    () => ({
      changeBuckets: context.changeBuckets,
      createKey,
      getChangeByKey,
      trackChange,
      updateChange,
      updateChanges,
      untrackChange,
      trackedChangeExists,
      saveChanges,
    }),
    [context.changeBuckets],
  );

  return (
    <LocalStorageContext.Provider value={_context}>
      <LocalStorageDispatchContext.Provider value={dispatch}>{children}</LocalStorageDispatchContext.Provider>
    </LocalStorageContext.Provider>
  );
};

/**
 * Provides the local storage context
 * @returns A context
 */
export const useLocalStorageContext = (): localStorageContextAttributes | null => useContext(LocalStorageContext);

/**
 * Custom type for the reducer action that works with local storage context
 */
type localStorageReducerAction = {
  type: LocalStorageActionType;
  payload: ChangeBucket | Array<ChangeBucket>;
};

/**
 * Set is used to set the containers, updateSingle is used to update a single container, updateAll
 * is used to update all the containers
 * @param context - The state to be updated
 * @param action - The action to be executed by the reducer
 * @returns Updated state
 */
const localStorageReducer = (
  context: localStorageContextAttributes,
  action: localStorageReducerAction,
): localStorageContextAttributes => {
  switch (action.type) {
    case LocalStorageActionType.updateSingleBucket: {
      const updatedBucket = action.payload as ChangeBucket;
      const updatedBuckets: Array<ChangeBucket> = context.changeBuckets.map((bucket: ChangeBucket) =>
        bucket.key === updatedBucket.key ? updatedBucket : bucket,
      );

      return { ...context, changeBuckets: updatedBuckets };
    }
    case LocalStorageActionType.updateAllBuckets: {
      const changeBuckets = action.payload as Array<ChangeBucket>;

      return { ...context, changeBuckets };
    }
  }
};
