import axios, { AxiosRequestConfig } from "axios";

// Auth Imports
import { webApiConfig } from "./ApiConfiguration";
import { getToken } from "./Auth/Auth";
import { FaqItem } from "../models/Faqs";
import { Method } from "../models/enums";

/**
 * Generic function to setup an API call
 * @param endpoint - The endpoint to send the request to
 * @returns The response from the API
 */
export const genericFaqCall = async (
  method: Method,
  clientId: string | null,
  item?: FaqItem,
  endpoint: string = "",
): Promise<Array<FaqItem>> => {
  try {
    // set the base endpoint for file uploads
    const url = `${webApiConfig.endpointUrl}/Faq${endpoint}`;
    const token = await getToken();

    const requestConfig: AxiosRequestConfig = {
      params: {
        clientId,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    switch (method) {
      case Method.Get:
        return (await axios.get<Array<FaqItem>>(url, requestConfig)).data;
      case Method.Post:
        return await axios.post(url, item, requestConfig);
      case Method.Delete:
        return await axios.delete(url, requestConfig);
      default:
        throw new Error("Unsupported method for faq request");
    }
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(
        `API call failed, error message: ${error.message}, request url: ${error.config?.url}, info: ${error.response?.data.title}`,
      );
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/** Get the Faq items from the database */
export const getFaqItems = async (clientId: string | null): Promise<Array<FaqItem>> =>
  await genericFaqCall(Method.Get, clientId);

/**
 * Add new Faq items to external database
 * @param data - The Faq items to add
 */
export const addFaqItem = (data: FaqItem, clientId: string | null): void => {
  void genericFaqCall(Method.Post, clientId, data);
};

/** Delete all Faq items from the database */
export const deleteFaqItem = (question: string, clientId: string | null): void => {
  const endpoint = `/${encodeURIComponent(question)}`;
  void genericFaqCall(Method.Delete, clientId, undefined, endpoint);
};
