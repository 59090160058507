import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./pages/App";

// MSAL imports
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

// Router Imports
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// Css
import "./index.css";

// MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);

// Embeded Chatbot interactions script

// Account Selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload != null) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

// Use BrowserRouter here, because <App> depends on this context
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <App pca={msalInstance} />
      </BrowserRouter>
    </DndProvider>
  </StrictMode>,
);
