import React, { FC } from "react";

// Component Imports
import { Box, Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DrawerHeader } from "./DrawerHeaderSpacer";
import DrawerContents from "./DrawerContents";

export interface FlyoutDrawerProps {
  drawerOpen: boolean;
  drawerWidth: number;
}

/**
 * FlyoutDrawer Component
 * @param drawerOpen - Whether the drawer is open.
 * @param drawerWidth - The width of the drawer.
 * @returns A React component representing the FlyoutDrawer to display and edit the dialogs.
 */
const FlyoutDrawer: FC<FlyoutDrawerProps> = ({ drawerOpen, drawerWidth }) => {
  // Initialize navigation hook
  const navigate = useNavigate();

  return (
    <Drawer
      data-testid="drawer"
      key="app-drawer"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      open={drawerOpen}
      anchor="left"
      variant="persistent"
    >
      <Box data-testid="box">
        <DrawerHeader data-testid="drawer-header" />
        <DrawerContents navigate={navigate} />
      </Box>
    </Drawer>
  );
};

export default FlyoutDrawer;
