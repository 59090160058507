// Authorization Imports
import { SilentRequest } from "@azure/msal-browser";

export type WebApiConfig = {
  endpointUrl?: string;
  subscriptionKey?: string;
};

export const webApiConfig: WebApiConfig = {
  endpointUrl: process.env.REACT_APP_WEBAPI_ENDPOINT as string,
  subscriptionKey: process.env.REACT_APP_WEBAPI_GATEWAY_SUBSCRIPTION_KEY as string,
};

export const WebApiTokenRequest: SilentRequest = {
  scopes: [process.env.REACT_APP_WEBAPI_READ_SCOPE!],
};
