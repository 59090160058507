import { KnowledgeSource, KnowledgeSourceType, UrlConfig } from "../../models/KnowledgeSource";

/** Create a visual timestamp of the give datetime string which is in UTC format */
export const dateTimeRepresentation = (date: string): string => {
  const userTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return new Date(date).toLocaleString("en-GB", {
    timeZone: userTimezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

/** Check if source is of Url type */
export const isUrlType = (type: KnowledgeSourceType): boolean => {
  if (
    type === KnowledgeSourceType.AtlassianWiki ||
    type === KnowledgeSourceType.AzureDevOpsWiki ||
    type === KnowledgeSourceType.GoogleDrive ||
    type === KnowledgeSourceType.SharePoint ||
    type === KnowledgeSourceType.UrlScraper
  ) {
    return true;
  }

  return false;
};

/** Check if source is of Url type */
export const isDocType = (type: KnowledgeSourceType): boolean => type === KnowledgeSourceType.DocumentUpload;

/** Check if url is a duplicate */
export const isUrlDuplicate = (source: KnowledgeSource, allSources: Array<KnowledgeSource>): boolean => {
  const duplicate: boolean = allSources.some(
    (diffSource) =>
      diffSource.name !== source.name && (diffSource.config as UrlConfig).url === (source.config as UrlConfig).url,
  );

  return duplicate;
};

/** Check if the url is valid */
export const isUrlInvalid = (source: KnowledgeSource): boolean => {
  const { url } = source.config as UrlConfig;

  if (url !== "") {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const testUrl = new URL(url);
      if (!url.startsWith("https://")) {
        return true;
      }
    } catch (error) {
      return true;
    }
  }

  return false;
};

/** */
export class SyncError extends Error {
  /** */
  constructor(message?: string) {
    super(message);
    this.name = "SyncError";
  }
}
