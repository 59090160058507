/**
 * Delay function
 * @param ms - time in milliseconds to delay follow up function.
 * @returns Promise<void>
 */
const delay = async (ms: number): Promise<void> => {
  await new Promise((resolve) => setTimeout(resolve, ms));
};

export default delay;
