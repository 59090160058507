import { LanguageText } from "./Language";

export const answer: Answer = {
  key: "",
  languages: [{ key: process.env.REACT_APP_GLOBAL_LANGUAGE!, value: "" }],
  type: "",
};

export type Answer = {
  key: string;
  languages: Array<LanguageText>;
  type: string;
};
