import React from "react";

// MUI Imports
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GridColDef, GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";

// Model Imports
import { messageTextType } from "../../../models/models";

/**
 * Renders a custom Messge type selector cell and its values
 */
const RenderSelectMessageType = (props: GridRenderCellParams<any, string>): JSX.Element => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  /**
   * Handles the change event when the select value is changed.
   * Updates the cell's value in the data grid's edit mode using the 'apiRef'.
   * @param event - The React synthetic event representing the change event.
   * @param newValue - The new value of the select (true or false).
   */
  const onChangeType = (event: SelectChangeEvent): void => {
    void apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <FormControl fullWidth variant="filled" hiddenLabel margin="dense" size="medium">
      <Select onChange={onChangeType} value={value}>
        {messageTextType.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

/**
 * Renders an editable select cell for a data grid column.
 * This function is used as the 'renderCell' prop for a column definition.
 * @param params - An object containing information about the cell and its value.
 * @returns A JSX element representing the editable select cell.
 */
export const renderSelectMessageType: GridColDef["renderCell"] = (params) => <RenderSelectMessageType {...params} />;
