import React, { FC, useState, useEffect, useRef } from "react";
import { highlight, Highlight } from "../../../models/Highlights";

// Material Imports
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "@mui/icons-material/Key";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import TextField from "@mui/material/TextField";
import TranslateIcon from "@mui/icons-material/Translate";

/*
 * TODO: possibly fix the model typing to work with the MUI DateTimePickers
 * Localization Imports
 */
import "moment/locale/nl";
import moment from "moment";

interface CreateHighlightDialogProps {
  executable: (update: Highlight) => void;
  language: string;
}

/**
 * A functional component representing the 'CreateHighlightDialog'.
 * @param executable - Function to execute when the dialog content is submitted.
 * @param language - The language for the 'CreateHighlightDialog' component.
 * @returns JSX element representing the 'CreateHighlightDialog'.
 */
const CreateHighlightDialog: FC<CreateHighlightDialogProps> = ({ executable, language }) => {
  // set default language with empty value
  const [newHighlight, setNewHighlight] = useState<Highlight>({
    ...highlight,
    languages: [{ key: language, value: "" }],
  });
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");

  /**
   * Handles opening the dialog with the specified scroll type.
   * @param scrollType - The scroll type for the dialog (e.g., 'paper', 'body', 'paperScrollBody', etc.).
   */
  const handleOpen = (scrollType: DialogProps["scroll"]) => () => {
    setScroll(scrollType);
    setOpen(true);
  };

  /**
   * Handles closing the dialog and resetting the 'newHighlight' state to its initial values.
   */
  const handleClose = (): void => {
    setNewHighlight({
      ...highlight,
      languages: [{ key: language, value: "" }],
    });
    setOpen(false);
  };

  /**
   * Ref to the description element for accessibility purposes.
   */
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    } else {
      return () => {
        setNewHighlight({
          ...highlight,
          languages: [{ key: language, value: "" }],
        });
      };
    }
  }, [open]);

  /**
   * Handles the change event when the input values are changed.
   * Updates the corresponding properties in the 'newHighlight' state based on the event target's 'name'.
   * @param event - The React change event representing the change in the input value.
   */
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value, checked } = event.target;
    const updatedNewHighlight = { ...newHighlight };

    switch (name) {
      case "enabled":
        updatedNewHighlight.enabled = checked;
        break;
      case "repeat":
        updatedNewHighlight.repeat = checked;
        break;
      case "timeStampFrom":
        if (updatedNewHighlight.timeStampTo !== "") {
          if (value < updatedNewHighlight.timeStampTo) {
            updatedNewHighlight[name] = moment(value).format("yyyy-MM-DDTHH:mm");
          }
        } else {
          updatedNewHighlight[name] = moment(value).format("yyyy-MM-DDTHH:mm");
        }
        break;
      case "timeStampTo":
        if (updatedNewHighlight.timeStampFrom !== "") {
          if (value > updatedNewHighlight.timeStampFrom) {
            updatedNewHighlight[name] = moment(value).format("yyyy-MM-DDTHH:mm");
          }
        } else {
          updatedNewHighlight[name] = moment(value).format("yyyy-MM-DDTHH:mm");
        }
        break;
      case "timeSpan":
        updatedNewHighlight.timeSpan = parseInt(value);
        break;
      case "key":
        updatedNewHighlight.key = value;
        break;
      case "value":
        updatedNewHighlight.languages[0].value = value;
        break;
    }

    setNewHighlight(updatedNewHighlight);
  };

  return (
    <>
      <Button onClick={handleOpen("paper")} variant="outlined">
        Nieuwe Highlight
      </Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll}>
        <DialogTitle>Nieuwe Highlight</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="new-highlight-description" borderBottom={"1px solid #dee2e6"} marginBottom={"10px"}>
            Vul de benodigde informatie in om een nieuwe record van het type highlight toe te voegen aan de chatbot.
            Onderwerp en beschrijving zijn verplicht om in te vullen.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                id="input-key-wIcon"
                label="Onderwerp"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                fullWidth
                value={newHighlight.key}
                onChange={onInputChange}
                name="key"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="input-lang-wIcon"
                label="Taal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TranslateIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                fullWidth
                defaultValue={language}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="input-desc-wIcon"
                label="Beschrijving"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LabelImportantIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                multiline
                minRows={2}
                maxRows={4}
                fullWidth
                value={newHighlight.languages.find(({ key }) => key === language)?.value}
                onChange={onInputChange}
                name="value"
              />
            </Grid>
            <Grid item xs={5}>
              <input
                style={{ height: "40px" }}
                type="datetime-local"
                onChange={onInputChange}
                name="timeStampFrom"
                value={newHighlight.timeStampFrom}
                min={moment(Date.now()).format("yyyy-MM-DDTHH:mm")}
              />
            </Grid>
            <Grid item xs={5}>
              <input
                style={{ height: "40px" }}
                type="datetime-local"
                onChange={onInputChange}
                name="timeStampTo"
                value={newHighlight.timeStampTo}
                min={moment(Date.now()).format("yyyy-MM-DDTHH:mm")}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="input-days-wIcon"
                label="Dagen"
                type="number"
                aria-valuemin={0}
                InputProps={{
                  inputProps: { min: 0, max: 365 },
                  startAdornment: <InputAdornment position="start">D:</InputAdornment>,
                }}
                variant="standard"
                fullWidth
                value={newHighlight.timeSpan}
                onChange={onInputChange}
                name="timeSpan"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Repeat"
                control={<Checkbox checked={newHighlight.repeat} onChange={onInputChange} name="repeat" />}
              />
              <FormControlLabel
                label="Enabled"
                control={<Checkbox checked={newHighlight.enabled} onChange={onInputChange} name="enabled" />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={newHighlight.key === ""}
            onClick={() => {
              executable(newHighlight);
              handleClose();
            }}
          >
            Opslaan
          </Button>
          <Button onClick={handleClose}>Annuleren</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateHighlightDialog;
