// #region Change Tracking Models

// TODO: Strictly typed object creation support
// Highlights, Dialogs, etc.
/**
 * Type that allows us to keep track of individual changes in a container
 * Future addition may be User Data
 */
export type TrackedChange = {
  key: string;
  containerName: string;
  oldValue?: any; // If only oldvalue exists, item may be deleted
  newValue?: any; // If only newvalue exists, item may be new
  changeType: ChangeType;
  displayOrder: Array<string>;
  lastModified: Date;
  live: boolean;
};

/**
 * type that gives us more information regard the sort of change that was made
 */
export enum ChangeType {
  New = "New",
  Update = "Update",
  Delete = "Delete",
}

/**
 * Type that allows us to create a bucket to track local changes
 */
export type ChangeBucket = {
  key: string;
  text: string;
  changes: Array<TrackedChange>;
};

/**
 * Template array that allows us to easily create copies
 */
export const changeBucketTemplateArray: Array<ChangeBucket> = [
  {
    key: "answers",
    text: "Berichten",
    changes: [],
  },
  {
    key: "dialogs",
    text: "Dialogen",
    changes: [],
  },
  {
    key: "highlights",
    text: "Highlights",
    changes: [],
  },
  {
    key: "groupincidents",
    text: "Groepsincidenten",
    changes: [],
  },
  {
    key: "knowledgesources",
    text: "Kennisbronnen",
    changes: [],
  },
];

// #endregion
