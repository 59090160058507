import React, { FC, useEffect } from "react";

// Material Imports
import { Stack } from "@mui/material";

// Page Imports
import Header from "../../components/Headers/Header";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

// TODO: build improved login page
/**
 * Renders the authenticator page
 * @returns A React component representing the authenticator page
 */
const Authenticator: FC = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance.ssoSilent(loginRequest).catch(() => {
      instance.loginRedirect(loginRequest).catch((e) => {
        throw new Error(e); //  Maybe add logging here instead of throwing an error
      });
    });
  }, []);

  return (
    <>
      <Header authenticated={false} />
      <Stack direction="column" alignItems="center" gap={2} justifyContent="center" p={10}>
        <h1>Welkom terug bij de admin tool!</h1>
        <p>Je moet eerst inloggen om gebruik te kunnen maken van de admin tool.</p>
      </Stack>
    </>
  );
};
export default Authenticator;
