import { TabPaths } from "./enums";

const EnableNLUFeatureFlag: boolean = process.env.REACT_APP_ENABLE_NLU_QUESTION_ANSWERING === "true";
const EnableHiglightsFeatureFlag: boolean = process.env.REACT_APP_ENABLE_HIGHLIGHTS === "true";
const EnableGroupIncidentsFeatureFlag: boolean = process.env.REACT_APP_ENABLE_GROUP_INCIDENTS === "true";

/** Type that allows us to represent a drawer item */
export type DrawerItem = {
  key: string;
  tabName: string;
  urlPath: string;
  tabOrder: number;
};

/** All the drawer items that belong to this instance with tabOrder as default order */
const allDrawerItems: Array<DrawerItem> = [
  {
    key: "questions",
    tabName: "Vragen",
    urlPath: TabPaths.Questions,
    tabOrder: 0,
  },
  {
    key: "answers",
    tabName: "Berichten",
    urlPath: TabPaths.Messages,
    tabOrder: 1,
  },
  {
    key: "dialogs",
    tabName: "Dialogen",
    urlPath: TabPaths.Dialogs,
    tabOrder: 2,
  },
  {
    key: "highlights",
    tabName: "Highlights",
    urlPath: TabPaths.Highlights,
    tabOrder: 3,
  },
  {
    key: "groupincidents",
    tabName: "Groepsincidenten",
    urlPath: TabPaths.GroupsIncidents,
    tabOrder: 4,
  },
  {
    key: "faq",
    tabName: "Faq",
    urlPath: TabPaths.Faq,
    tabOrder: 5,
  },
  {
    key: "knowledgesources",
    tabName: "Kennisbronnen",
    urlPath: TabPaths.KnowledgeSources,
    tabOrder: 6,
  },
];

// If feature is not flagged, filter it out
const filter: Array<string> = [];
if (!EnableGroupIncidentsFeatureFlag) {
  filter.push("groupincidents");
}
if (!EnableHiglightsFeatureFlag) {
  filter.push("highlights");
}
if (!EnableNLUFeatureFlag) {
  filter.push("dialogs");
  filter.push("questions");
}

// Remove drawers if flagged
const updatedDrawerItems = allDrawerItems.filter((item) => !filter.includes(item.key));

// The drawer items with their adapted index, so highlighted tab selection works
export const drawerItems: Array<DrawerItem> = updatedDrawerItems.map((item, tabOrder) => ({ ...item, tabOrder }));
