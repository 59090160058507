import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

// Auth Imports
import { webApiConfig } from "./ApiConfiguration";
import { getToken } from "./Auth/Auth";

export enum StorageEnvironment {
  Staging = 0,
  Live = 1,
}

/**
 * Perform proper logging of axios error
 * @param error - The axios error to be logged
 * @returns A string with information about the error
 */
export const logAxiosError = (error: AxiosError): string => {
  let errors = "";
  Object.entries((error.response as AxiosResponse)?.data.errors).forEach(([key, value]) => {
    errors = errors.concat(`(${key}: ${value})`);
  });

  return [
    `API call axios malfunction, error message: ${error.message}, `,
    `request url: ${error.config?.url}, `,
    `info: ${(error.response as AxiosResponse)?.data.title}, `,
    `errors: ${errors}`,
  ].join("");
};

// #region Basic API requests.

/**
 * Get all the containers from the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the storage that can be put directly into the context.
 */
export const getContainer = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  clientId: string | null = null,
): Promise<Array<T>> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
        clientId,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request.
    const response = await axios.get<Array<T>>(url, requestConfig);

    // store the fetched container.
    return response.data;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(logAxiosError(error));
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Get an item from a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be put
 * @returns an axios response with the response data.
 */
export const getItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  itemKey: string,
  clientId: string | null = null,
): Promise<T> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}/${itemKey}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
        clientId,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request.
    const response = await axios.get<T>(url, requestConfig);

    return response.data;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(logAxiosError(error));
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * create an item in a specified container that matches the item supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be put
 * @returns an axios response with the response data.
 */
export const createItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  item: T,
): Promise<void> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the PUT request.
    await axios.post<T>(url, item, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(logAxiosError(error));
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * create an item in a specified container that matches the item supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be put
 * @returns an axios response with the response data.
 */
export const createItems = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  items: Array<T>,
  clientId: string | null = null,
): Promise<void> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}/List`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
        clientId,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the PUT request.
    await axios.post<Array<T>>(url, items, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(logAxiosError(error));
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * delete an item in a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be put
 * @returns an axios response with the response data.
 */
export const deleteItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  itemKey: string,
  clientId: string | null = null,
): Promise<void> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}/${itemKey}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
        clientId,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the DELETE request.
    await axios.delete<T>(url, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(logAxiosError(error));
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * update an item in a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param key - the key of the item that will be updated
 * @param item - the item that will be updated
 * @returns an axios response with the response data.
 */
export const updateItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  key: string,
  item: any,
  clientId: string | null = null,
): Promise<void> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}/${key}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
        clientId,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    await axios.put<T>(url, item, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(logAxiosError(error));
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * update an item in a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param key - the key of the item that will be updated
 * @param items - the items that will be updated
 * @returns an axios response with the response data.
 */
export const updateItems = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  item: any,
  clientId: string | null = null,
): Promise<void> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}/List`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
        clientId,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    await axios.put<T>(url, item, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(logAxiosError(error));
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

// #endregion
