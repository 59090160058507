import { useEffect, FC, useState } from "react";
import { useClientContext } from "../contexts/ClientContext";

interface ExternalScriptProps {
  src: string;
}

/**
 * Component that loads an external webchat into the DOM
 * @param src - The source URL of the webchat script to load
 */
const Webchat: FC<ExternalScriptProps> = ({ src }) => {
  const isMultiTenant: boolean = process.env.REACT_APP_IS_MULTI_TENANT === "true";

  const ClientContext = useClientContext();

  const [clientId, setClientId] = useState<string | null>(ClientContext!.clientId);
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (ClientContext !== null) {
      setClientId(ClientContext.clientId);
    }
  }, [ClientContext]);

  useEffect(() => {
    if (isScriptLoaded) return;
    if (isMultiTenant && clientId === null) return;

    const script = document.createElement("script");
    script.src = src;
    script.id = "webchat-script";
    script.async = true;

    if (!isMultiTenant) {
      window.chatParameters = { floatLeft: true };
      if (document.getElementById("webchat-script") === null) {
        document.head.appendChild(script);
      }
      setIsScriptLoaded(true);
    }

    if (isMultiTenant && clientId !== null) {
      window.chatParameters = { clientId, floatLeft: true };
      if (document.getElementById("webchat-script") === null) {
        document.head.appendChild(script);
      }
      setIsScriptLoaded(true);
    }
  }, [src, isMultiTenant, clientId]);

  return null;
};

export default Webchat;
