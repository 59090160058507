import { BotParameters } from "../models/BotParameters";

/**
 * Send change client id event to the webchat to change the client id and restart chat.
 * @param clientId - The new client id to use
 */
export const setWebChatClientId = (clientId: string): void => {
  /**
   * Check if the webchat script has loaded and call the changeClientId function from the cnd script
   */
  const checkUpdateChatParameters = (): void => {
    if (typeof window.changeClientId === "function") {
      window.changeClientId(clientId);
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    }
  };

  const intervalId = setInterval(checkUpdateChatParameters, 100);
  const timeoutId = setTimeout(() => {
    clearInterval(intervalId);
  }, 20000);
};

/**
 * Send change style event to the webchat to change the style of the chat. Try to avoid using clientId here.
 * @param style - The style properties to update
 */
export const setChatStyle = (style: BotParameters): void => {
  /**
   * Check if the webchat script has loaded and call the updateChatStyle function from the cdn script
   */
  const updateChatStyle = (): void => {
    if (typeof window.updateChatStyle === "function") {
      window.updateChatStyle(style);
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    }
  };

  const intervalId = setInterval(updateChatStyle, 100);
  const timeoutId = setTimeout(() => {
    clearInterval(intervalId);
  }, 20000);
};
