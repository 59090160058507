import React from "react";

// Material Imports
import { useGridApiContext, GridRenderCellParams, GridRenderEditCellParams, GridColDef } from "@mui/x-data-grid";
import moment from "moment";

/**
 * Renders a custom date cell for the data grid.
 * @param props - An object containing information about the cell and its value.
 * @returns A JSX element representing the custom date cell.
 */
const RenderCustomDateCell = (props: GridRenderCellParams<any, string>): JSX.Element => (
  <input
    style={{ height: "40px" }}
    type="datetime-local"
    value={moment(props.value).format("yyyy-MM-DDTHH:mm")}
    readOnly={true}
  />
);

/**
 * Renders a custom date cell for a data grid column.
 * This function is used as the 'renderCell' prop for a column definition.
 * @param params - An object containing information about the cell and its value.
 * @returns A JSX element representing the custom date cell.
 */
export const renderCustomDateCell: GridColDef["renderCell"] = (params) => <RenderCustomDateCell {...params} />;

/**
 * Renders a custom date edit cell for the data grid.
 * @param props - An object containing information about the edit cell and its value.
 * @returns A JSX element representing the custom date edit cell.
 */
const RenderCustomDateEditCell = (props: GridRenderEditCellParams<any, string>): JSX.Element => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  /**
   * Handles the change event when the value of the custom date edit cell is changed.
   * Updates the cell's value in the data grid's edit mode using the 'apiRef'.
   * @param event - The change event from the input element.
   */
  const handleChange = (event: any): void => {
    void apiRef.current.setEditCellValue({ id, field, value: moment(event.target.value).format("yyyy-MM-DDTHH:mm") });
  };

  return (
    <input
      style={{ height: "40px" }}
      type="datetime-local"
      onChange={handleChange}
      value={moment(value).format("yyyy-MM-DDTHH:mm")}
    />
  );
};

/**
 * Renders a custom date edit cell for a data grid column.
 * This function is used as the 'renderEditCell' prop for a column definition.
 * @param params - An object containing information about the edit cell and its value.
 * @returns A JSX element representing the custom date edit cell.
 */
export const renderCustomDateEditCell: GridColDef["renderEditCell"] = (params) => (
  <RenderCustomDateEditCell {...params} />
);
