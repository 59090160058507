export enum MessageType {
  MESSAGE = "message",
  BUTTONTEXT = "buttonText",
  QUESTION = "question",
}

export enum Method {
  Get,
  Post,
  Put,
  Delete,
}

/** Used for routing of drawer tabs */
export enum TabPaths {
  Home = "",
  Dialogs = "/Dialogs",
  Faq = "/Faq",
  GroupsIncidents = "/GroupsIncidents",
  Highlights = "/Highlights",
  KnowledgeSources = "/KnowledgeSources",
  Messages = "/Messages",
  Questions = "/Questions",
}

export enum ContainerNames {
  Answers = "answers",
  Dialogs = "dialogs",
  Faq = "faq",
  GroupIncidents = "groupincidents",
  Highlights = "highlights",
  Intents = "intents",
  IntentGroups = "intentgroups",
  KnowledgeSources = "knowledgesources",
}

export enum ShowOptionsComponentType {
  Question = "question",
  Message = "message",
  DescQuestion = "descQuestion",
  Button = "buttonText",
}

export enum AdminPanelTitles {
  // Tab identifies itself as
  TabTitle = "Admin Panel",
}

export enum CookieNames {
  // the cookie name used for the last used clientId by the user
  MULTI_TENANT_LAST_CLIENT_ID = "MULTI_TENANT_LAST_CLIENT_ID",
}
