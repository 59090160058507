import React, { FC, memo, useState, useEffect, ReactElement } from "react";

// Component Imports
import ConfirmationDialog, { ConfirmationDialogProps } from "../Dialogs/ConfirmationDialog/ConfirmationDialog";
import CreateHighlightDialog from "../Dialogs/NewEntryDialogs/CreateHighlightDialog";
import CreateAnswerDialog from "../Dialogs/NewEntryDialogs/CreateAnswerDialog";

// MUI Imports
import HistoryIcon from "@mui/icons-material/History";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  DataGrid,
  GridActionsCellItem,
  gridClasses,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { Box, Grid } from "@mui/material";

// Data Imports
import { ChangeType } from "../../models/ChangeTracking";
import { ContainerNames } from "../../models/enums";

// Context Imports
import {
  localStorageContextAttributes,
  useLocalStorageContext,
} from "../../contexts/ChangeTracking/LocalStorageContext";
import {
  externalStorageContextAttributes,
  useExternalStorageContext,
} from "../../contexts/ChangeTracking/ExternalStorageContext";

// Style Imports
import "../../styles/PageLayout.css";
import { CursedArrayType, CursedType } from "../Editors/ModularEditor/ModularEditor";
import { DEFlagIcon, FRFlagIcon, GBFlagIcon, NLFlagIcon } from "../Icons/FlagIcons";
import { Language, languages, LanguageText } from "../../models/Language";
import CustomToolTip from "../CustomToolTip";

import AddOrEditLanguageDialog, {
  AddOrEditLanguageDialogProps,
  defaultAddOrEditLanguageDialogProps,
} from "../Editors/ModularEditor/AddOrEditLanguageDialog";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    executable: (update: any) => void;
  }
}

interface CustomDataGridProps {
  onCreate: (createItem: CursedType) => void;
  onUpdate: (oldRow: CursedType, newRow: CursedType) => void;
  onDelete: (key: string) => void;
  data: CursedArrayType;
  setData: React.Dispatch<React.SetStateAction<CursedArrayType>>;
  columns: Array<GridColDef>;
  containerName: ContainerNames;
}

/**
 * Modular Datagrid component
 * @param onUpdate - The function to be called when the data is updated.
 * @param columns - The columns to be displayed in the grid.
 * @param containerName - The name of the container to be displayed in the grid.
 */
const CustomDataGrid: FC<CustomDataGridProps> = ({
  onCreate,
  onUpdate,
  onDelete,
  data,
  setData,
  columns,
  containerName,
}) => {
  // Keys of items changed, used for visualisation
  const [localChanges, setLocalChanges] = useState<Array<string>>([]);
  const [stagedChanges, setStagedChanges] = useState<Array<string>>([]);

  // Confirmation dialog properties
  const [confirmProps, setConfirmProps] = useState<ConfirmationDialogProps>();

  // Data Grid states
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  // Context
  const localStorageContext = useLocalStorageContext();
  const externalStorageContext = useExternalStorageContext();

  const [currentKey, setCurrentKey] = useState<string>("");
  const [languageDialogProps, setLanguageDialogProps] = useState<AddOrEditLanguageDialogProps>(
    defaultAddOrEditLanguageDialogProps,
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const defaultLanguage: string = process.env.REACT_APP_GLOBAL_LANGUAGE!;

  /**
   * Handles the row edit stop event.
   */
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params: GridRowParams): void => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  /**
   * Handles cancel click on edit
   */
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  /**
   * Handles the row model change event.
   */
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel): void => {
    setRowModesModel(newRowModesModel);
  };

  /**
   * Handles the row edit click event.
   */
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  /**
   * Handles the save click event during row edit.
   */
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const commonColumns: Array<GridColDef> = [
    {
      field: "key",
      headerName: "Onderwerp",
      type: "text",
      minWidth: 200,
      hideable: false,
      editable: false,
    },
    {
      field: "value",
      headerName: "Beschrijving",
      type: "text",
      flex: 1,
      minWidth: 400,
      hideable: false,
      editable: true,
      /**
       * Gets the value of the main language from the languages array
       * @param params - The row parameters
       * @returns The value of the main language
       */
      valueGetter: ({ row }: GridRowModel) => {
        if (row.languages.length > 0) {
          const languageIndex: number = row.languages.findIndex((lang: LanguageText) => lang.key === defaultLanguage);
          if (languageIndex !== -1 && row.languages[languageIndex].value !== "") {
            return row.languages[languageIndex].value;
          }
        }

        return "";
      },
    },
    {
      flex: 1,
      maxWidth: 250,
      minWidth: 100,
      field: "andereTalen",
      headerName: "Andere Talen",
      type: "actions",
      /**
       * Gets the actions for the row
       * @param params - The row parameters
       * @returns The actions for the row
       */
      getActions: ({ row }: GridRowModel) => {
        const actions: Array<ReactElement> = [];
        const data = row as CursedType;

        /**
         * Function to create the action for the language specified
         * @param languageKey - The key of the language
         * @param title - The title of the language
         * @param icon - The icon of the language
         * @returns The actions flags for language
         */
        const createAction = (languageKey: string, title: string, icon: any): JSX.Element => (
          <GridActionsCellItem
            key={languageKey}
            icon={<CustomToolTip title={title} child={icon} />}
            label={`${languageKey}-text`}
            onClick={() => {
              setCurrentKey(data.key);
              const languageValue: string =
                data.languages.find((language: LanguageText) => language.key === languageKey)?.value ?? "";
              handleLanguageDialogProps(`Wijzig ${title} tekst`, languages, languageKey, languageValue, true);
              setDialogOpen(true);
            }}
          />
        );

        if (
          data.languages !== null &&
          data.languages !== undefined &&
          languages.some((language) => !data.languages.some((lang: LanguageText) => lang.key === language.key))
        ) {
          actions.push(
            <GridActionsCellItem
              key={`${data.key}`}
              icon={<CustomToolTip title="Voeg een nieuwe taal toe" child={<AddIcon />} />}
              label="add-new-language"
              onClick={() => {
                setCurrentKey(data.key);
                const filteredLanguages = languages.filter(
                  (language) => !data.languages.some((lang: LanguageText) => lang.key === language.key),
                );
                handleLanguageDialogProps("Voeg een nieuwe taal toe", filteredLanguages);
                setDialogOpen(true);
              }}
            />,
          );
        }

        languages.forEach((language) => {
          if (
            language.key !== defaultLanguage &&
            data.languages.some((lang: LanguageText) => lang.key === language.key)
          ) {
            actions.push(createAction(language.key, language.name, getFlagIcon(language.key)));
          }
        });

        return actions;
      },
    },
  ];

  const actionColumn: GridColDef = {
    field: "actions",
    headerName: "Acties",
    type: "actions",
    width: 150,
    hideable: false,

    /**
     * Returns an array of React elements representing action items for a grid cell.
     * @param params - An object containing information about the row and other parameters.
     * @returns An array of React elements representing action items for the grid cell.
     */
    getActions: ({ id, row }) => {
      const actions: Array<JSX.Element> = [];

      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        actions.push(
          <GridActionsCellItem key={`${id}_save`} icon={<SaveIcon />} label="Opslaan" onClick={handleSaveClick(id)} />,
        );
        actions.push(
          <GridActionsCellItem
            key={`${id}_cancel`}
            icon={<CloseIcon />}
            label="Sluit"
            onClick={handleCancelClick(id)}
          />,
        );

        return actions;
      }

      actions.push(
        <GridActionsCellItem key={`${id}_edit`} icon={<EditIcon />} label="Bewerken" onClick={handleEditClick(id)} />,
      );

      actions.push(
        <GridActionsCellItem
          key={`${id}_revert`}
          icon={<HistoryIcon />}
          label="Terugdraai"
          onClick={() => {
            throw new Error("Does not work");
          }}
        />,
      );

      actions.push(
        <GridActionsCellItem
          key={`${id}_delete`}
          icon={<DeleteIcon />}
          label="Verwijderen"
          onClick={openDeleteItemDialog(row.key)}
        />,
      );

      return actions;
    },
  };

  const allColumns: Array<GridColDef> = commonColumns.concat(columns);
  allColumns.push(actionColumn);

  useEffect(() => {
    setData(data);
    setLocalChanges(getLocalChanges);
    setStagedChanges(getStagedChanges);
  }, [data]);

  /**
   * Helper function to get the flag icon based on the language key
   * @param languageKey - The key of the language
   * @returns The flag icon for the language
   */
  const getFlagIcon = (languageKey: string): ReactElement => {
    switch (languageKey) {
      case "NL":
        return <NLFlagIcon />;
      case "EN":
        return <GBFlagIcon />;
      case "DE":
        return <DEFlagIcon />;
      case "FR":
        return <FRFlagIcon />;
      default:
        return <></>;
    }
  };

  /**
   * Adds a language to the incident
   * @param languageText - The language text to add to the incident
   * @returns void
   */
  const addOrUpdateLanguage = (languageText: LanguageText, key: string = currentKey): void => {
    const oldItem = data.find((item) => item.key === key);
    if (oldItem === undefined) throw new Error("impossible");

    let languages: Array<LanguageText>;
    if (oldItem.languages.some((language) => language.key === languageText.key)) {
      languages = oldItem.languages.map((language) => {
        if (language.key === languageText.key) return { ...language, value: languageText.value };

        return language;
      });
    } else {
      languages = [...oldItem.languages, languageText];
    }

    const updatedItem = { ...oldItem, languages };

    onUpdate(oldItem, updatedItem);
  };

  /**
   * Removes a language from the entry
   * @param languageKey - The language key to remove from the incident
   * @returns void
   */
  const removeLanguage = (languageKey: string): void => {
    const oldItem = data.find((item) => item.key === currentKey);
    if (oldItem === undefined) throw new Error("impossible");

    const updatedItem = { ...oldItem, languages: oldItem.languages.filter((language) => language.key !== languageKey) };

    onUpdate(oldItem, updatedItem);
  };

  /**
   * Opens the language dialog for editing or adding a language
   */
  const handleLanguageDialogProps = (
    title: string,
    languages: Array<Language>,
    dialogLanguage: string = "",
    dialogValue: string = "",
    isEdit: boolean = false,
  ): void => {
    setLanguageDialogProps({
      addLanguage: addOrUpdateLanguage,
      removeLanguage,
      isOpen: dialogOpen,
      isEdit,
      /** Close dialog */
      handleClose: () => {
        setDialogOpen(false);
      },
      languages,
      dialogValue,
      dialogLanguage,
      title,
    });
  };

  /**
   * Deletes an item with the given key from the data list and updates the component state and data with the new data.
   * @param key - The unique key of the item to be deleted.
   */
  const openDeleteItemDialog = (key: string) => () => {
    // Create the props for the confirmation dialog to ask for user confirmation before deleting
    const confirmProps: ConfirmationDialogProps = {
      open: true,
      handleClose: handleConfirmClose,
      title: "Bevestiging Verwijderen",
      button1Text: "Akkoord",
      button2Text: "Annuleer",
      description: "Weet je zeker dat je dit wilt verwijderen?",
      /** Delete item on confirmation */
      executable: () => {
        onDelete(key);
      },
    };

    // Show the confirmation dialog by setting the props for it
    setConfirmProps(confirmProps);
  };

  /**
   * Updates the data list with a new row data and the component state and data with the updated data.
   * @param newRow - The new data for the row to be updated.
   * @param oldRow - The previous data for the row to be updated.
   * @returns The updated row data.
   */
  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel): GridValidRowModel => {
    if (newRow === oldRow) {
      return oldRow;
    }

    // Map languages in case column 'value' changed
    const updatedLanguages = (oldRow as CursedType).languages.map((language) => {
      // If default language has been edited return that
      if (language.key === defaultLanguage && language.value !== newRow.value) {
        return {
          key: defaultLanguage,
          value: newRow.value,
        };
      }

      return language;
    });

    delete newRow.value;

    const updatedRow = { ...newRow, languages: updatedLanguages };

    onUpdate(oldRow as CursedType, updatedRow as CursedType);

    return updatedRow;
  };

  /**
   * Reusable function to format based on the storage context.
   * @param storageContext - The storage context to apply changes from.
   */
  const findChanges = (
    storageContext: localStorageContextAttributes | externalStorageContextAttributes,
  ): Array<string> => {
    const changeBucket = storageContext.changeBuckets.find((bucket) => bucket.key === containerName);
    if (changeBucket === undefined) return [];

    return changeBucket.changes
      .filter((change) => change.changeType !== ChangeType.Delete)
      .map((change) => change.newValue.key);
  };

  /**
   * Get the changes that have been made locally
   */
  const getLocalChanges = (): Array<string> => [...findChanges(localStorageContext!)];

  /**
   * Get the changes that have been made in staging
   */
  const getStagedChanges = (): Array<string> => [...findChanges(externalStorageContext!)];

  /**
   * Returns a string representing the class name for a row.
   * @param params - An object containing information about the row and other parameters.
   * @returns returns row bold if element has been edited. Otherwise returns empty string.
   */
  const getRowClassName = (params: GridRowModel): string => {
    if (stagedChanges.some((key) => key === params.row.key)) return "stage";
    if (localChanges.some((key) => key === params.row.key)) return "local";

    return "";
  };

  /**
   * Closes the confirmation dialog by setting the 'open' prop to 'false' and resetting other props.
   */
  const handleConfirmClose = (): void => {
    const confirmProps: ConfirmationDialogProps = {
      open: false,

      /**
       * Handles the close event of the confirmation dialog.
       * @returns void
       */
      handleClose: () => {},
      title: "",
      button1Text: "",
      button2Text: "",
      description: "",

      /**
       * Executes the function passed as a prop to the confirmation dialog.
       * @returns void
       */
      executable: () => {},
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Renders a custom dialog based on the 'containerName' prop.
   * This function is used to conditionally render different dialogs based on the 'containerName'.
   * @returns A JSX element representing the custom dialog.
   */
  const RenderCustomDialog = (): JSX.Element => {
    switch (containerName) {
      case ContainerNames.Highlights:
        return <CreateHighlightDialog executable={onCreate} language={defaultLanguage} />;
      case ContainerNames.Answers:
        return <CreateAnswerDialog executable={onCreate} language={defaultLanguage} />;
      default:
        return <></>;
    }
  };

  return (
    <Box height={"100%"}>
      {confirmProps != null ? <ConfirmationDialog {...confirmProps} /> : null}
      <Grid container height={"100%"}>
        <Grid item xs={12}>
          <RenderCustomDialog />
          <AddOrEditLanguageDialog
            isOpen={dialogOpen}
            handleClose={() => {
              setDialogOpen(false);
            }}
            languages={languageDialogProps.languages}
            addLanguage={(languageText: LanguageText) => {
              addOrUpdateLanguage(languageText);
            }}
            removeLanguage={(languageKey: string) => {
              removeLanguage(languageKey);
            }}
            dialogLanguage={languageDialogProps.dialogLanguage}
            dialogValue={languageDialogProps.dialogValue}
            title={languageDialogProps.title}
            isEdit={languageDialogProps.isEdit}
          />
        </Grid>
        <Grid item xs={12} height={"94%"}>
          <DataGrid
            sx={{
              [`.${gridClasses.row}`]: { "&:hover": { backgroundColor: "rgba(0,0,255,0.2)" } },

              [`.${gridClasses.row}.local`]: {
                fontWeight: "bold",
                backgroundColor: "rgba(0,0,255,0.1)",
              },
              [`.${gridClasses.row}.stage`]: {
                backgroundColor: "rgba(0,0,255,0.1)",
              },
            }}
            className=""
            columns={allColumns}
            rows={data}
            getRowClassName={getRowClassName}
            rowHeight={52}
            getRowId={(row: GridRowModel) => row.key}
            editMode="row"
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              throw new Error(error);
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            keepNonExistentRowsSelected
            initialState={{
              sorting: {
                sortModel: [{ field: "key", sort: "asc" }],
              },
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            localeText={{
              MuiTablePagination: {
                labelRowsPerPage: `Rijen per pagina:`,

                /**
                 * Display the pagination text as we wish.
                 */
                labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} van ${count}`,
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(CustomDataGrid);
