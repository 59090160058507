import React, { FC, useEffect, useRef, useState } from "react";

// Data Imports
import { getContainer, StorageEnvironment } from "../../../API/StorageInteraction";

// Component Imports
import CustomDataGrid from "../../CustomDataGrid/CustomDataGrid";

import {
  renderCustomDateCell,
  renderCustomDateEditCell,
} from "../../CustomDataGrid/DataGridComponents/RenderDateTimePicker";
import { renderSelectMessageType } from "../../CustomDataGrid/DataGridComponents/RenderSelectMesssageType";

// MUI Imports
import { GridColDef, GridValueFormatterParams, renderBooleanCell, renderEditBooleanCell } from "@mui/x-data-grid";
import { Box, CircularProgress } from "@mui/material";

// context imports
import { useLocalStorageContext } from "../../../contexts/ChangeTracking/LocalStorageContext";

// model imports
import { messageTextType } from "../../../models/models";
import { ContainerNames } from "../../../models/enums";

// The three possible cursed models
import { Answer } from "../../../models/Answers";
import { Highlight } from "../../../models/Highlights";
import { GroupIncident } from "../../../models/GroupIncidents";
import { ChangeType } from "../../../models/ChangeTracking";
import { updateChangeTracking } from "../../../contexts/ChangeTracking/UpdateChangeTracking";

// Fuck 'any' I wanna know what Cursed data I'm dealing with
export type CursedType = Highlight | GroupIncident | Answer;
export type CursedArrayType = Array<CursedType>;

/**
 * MultiEditorProps interface
 * @param containerName - The name of the container to use.
 */
interface ModularEditorProps {
  containerName: ContainerNames;
}

/**
 * Generic Editor Component for cursed types
 * @returns A Generic Editor component for cursed types
 */
const ModularEditor: FC<ModularEditorProps> = ({ containerName }) => {
  // TODO 4906: Add support for client ID selections swap uncomment the following two lines and use the client ID in the useEffect used to collect data
  // const ClientContext = useClientContext();
  // const [clientId, setClientId] = useState<string | null>(ClientContext!.clientId!);

  // Context
  const context = useLocalStorageContext();
  if (context === null) throw new Error("Context can not be null");

  // State
  const columns = useRef<Array<GridColDef>>([]);
  const [data, setData] = useState<CursedArrayType>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    void getContainer(StorageEnvironment.Staging, containerName).then((data) => {
      setData(data as CursedArrayType);
    });

    setLoading(false);
  }, [containerName]);

  useEffect(() => {
    switch (containerName) {
      case ContainerNames.Answers:
        columns.current = [
          {
            field: "type",
            headerName: "Type",
            type: "text",
            width: 150,
            editable: true,
            renderEditCell: renderSelectMessageType,

            /**
             * Gets the correct display text for the message type
             */
            valueFormatter: (params: GridValueFormatterParams<any>): string | undefined => {
              const type = messageTextType.find((item) => item.key === params.value);

              return type?.value;
            },
          },
        ];
        break;
      case ContainerNames.Highlights:
        columns.current = [
          {
            field: "timeStampFrom",
            headerName: "Periode van",
            type: "text",
            width: 200,
            editable: true,
            filterable: false,
            renderCell: renderCustomDateCell,
            renderEditCell: renderCustomDateEditCell,
          },
          {
            field: "timeStampTo",
            headerName: "Periode tot",
            type: "text",
            width: 200,
            editable: true,
            filterable: false,
            renderCell: renderCustomDateCell,
            renderEditCell: renderCustomDateEditCell,
          },
          {
            field: "repeat",
            headerName: "Herhalen",
            type: "boolean",
            width: 125,
            editable: true,
            renderCell: renderBooleanCell,
            renderEditCell: renderEditBooleanCell,
          },
          {
            field: "timeSpan",
            headerName: "Interval",
            type: "number",
            width: 125,
            editable: true,
          },
          {
            field: "enabled",
            headerName: "Ingeschakeld",
            type: "boolean",
            width: 125,
            editable: true,
            renderCell: renderBooleanCell,
            renderEditCell: renderEditBooleanCell,
          },
        ];
        break;
      case ContainerNames.GroupIncidents:
        columns.current = [
          {
            field: "enabled",
            headerName: "Ingeschakeld",
            type: "boolean",
            width: 125,
            editable: true,
            renderCell: renderBooleanCell,
            renderEditCell: renderEditBooleanCell,
          },
        ];
        break;
      default:
        columns.current = [];
    }
  }, [containerName]);

  /**
   * Editor update function that creates a dispatch action with the correct information
   * @param update - the update generated by the CustomDataGrid component that will be
   * put into the context
   * @returns void
   */
  const onCreate = (newItem: CursedType): void => {
    // Update local data
    const newData: CursedArrayType = [...data, newItem];
    setData(newData);

    updateChangeTracking(null, newItem, ChangeType.New, containerName, context);
  };

  /**
   * Update change bucket
   */
  const onUpdate = (oldValue: CursedType, newValue: CursedType): void => {
    // Update local data
    const newData: CursedArrayType = data.map((item) => (item.key === newValue.key ? newValue : item));

    setData(newData);

    // Update change tracking data
    updateChangeTracking(oldValue, newValue, ChangeType.Update, containerName, context);
  };

  /**
   * Editor update function that creates a dispatch action with the correct information
   * @param update - the update generated by the CustomDataGrid component that will be
   * put into the context
   * @returns void
   */
  const onDelete = (key: string): void => {
    // Find the index of the item with the given key
    const oldValue = data.find((item) => item.key === key);

    if (oldValue === undefined) throw new Error("Could not find item to delete");

    // Update local data
    const newData: CursedArrayType = data.filter((item) => item.key !== key);
    setData(newData);

    updateChangeTracking(oldValue, null, ChangeType.Delete, containerName, context);
  };

  return (
    <>
      {data !== undefined && !loading ? (
        <CustomDataGrid
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
          data={data}
          setData={setData}
          columns={columns.current}
          containerName={containerName}
        />
      ) : (
        <Box sx={{ display: "flex" }} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ModularEditor;
