import React, { FC, useState } from "react";

// Component Imports
import {
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Data Imports
import { LanguageText } from "../../../models/Language";
import { findDifference } from "../../../helpers/ObjectDifferences";

interface AccordionContentHighlightProps {
  record: Record<string, any>;
  differences?: Record<string, boolean | Record<string, boolean>>;
}

/**
 * An accordion content item for highlights.
 * @param record - The change whose information must be displayed.
 * @param order - the order in which the changes must be displayed, gained from a change's displayOrder property.
 */
const AccordionContentHighlight: FC<AccordionContentHighlightProps> = ({ record, differences }) => {
  // state
  const [languagesOpen, setLanguagesOpen] = useState<boolean>(false);
  const [periodOpen, setPeriodOpen] = useState<boolean>(false);

  /**
   * function to reduce code required in setting bg colors on differences.
   * @param set - whether it should be set or not.
   */
  const setBackgroundColor = (set: boolean): string => {
    if (set === undefined) return "white";
    else return set ? "rgba(0, 100, 180, 0.20)" : "white";
  };

  /**
   * Handle the opening of the languages section.
   */
  const handleLanguagesOpen = (): void => {
    setLanguagesOpen(!languagesOpen);
  };

  /**
   * Handle the opening of the period section.
   */
  const handlePeriodOpen = (): void => {
    setPeriodOpen(!periodOpen);
  };

  return (
    <List key={`highlight-change-preview`} disablePadding sx={{ width: "100%" }}>
      <ListItem
        data-testid={`item-key`}
        key={`highlight-change-preview-key`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("key", differences)),
        }}
      >
        <ListItemAvatar>
          <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Naam`} />
        </ListItemAvatar>
        <ListItemText>{record.key}</ListItemText>
      </ListItem>
      <ListItemButton
        data-testid={`expand-languages`}
        key={`highlight-change-preview-languages`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("languages", differences)),
        }}
        onClick={handleLanguagesOpen}
      >
        <ListItemAvatar>
          <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Talen`} />
        </ListItemAvatar>
        <ListItemSecondaryAction data-testid="expand-languages-actions">
          {languagesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemSecondaryAction>
      </ListItemButton>
      <Collapse in={languagesOpen} timeout={"auto"} unmountOnExit>
        <List
          key={`highlight-change-preview-languages-list`}
          disablePadding
          sx={{
            width: "100%",
            backgroundColor: setBackgroundColor(findDifference("languages", differences)),
          }}
        >
          {record.languages.map((language: LanguageText) => (
            <ListItem key={`highlight-change-preview-languages-${language.key}`}>
              <ListItemAvatar>
                <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={language.key} />
              </ListItemAvatar>
              <ListItemText>{language.value}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
      <ListItemButton
        key={`highlight-change-preview-period`}
        sx={{
          backgroundColor:
            findDifference("timeStampFrom", differences) || findDifference("timeStampTo", differences)
              ? setBackgroundColor(true)
              : setBackgroundColor(false),
        }}
        onClick={handlePeriodOpen}
      >
        <ListItemAvatar>
          <Chip
            data-testid="chip-periode"
            sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }}
            label={`Periode`}
          />
        </ListItemAvatar>
        <ListItemSecondaryAction>{periodOpen ? <ExpandLess /> : <ExpandMore />}</ListItemSecondaryAction>
      </ListItemButton>
      <Collapse in={periodOpen} timeout={"auto"} unmountOnExit>
        <List key={`highlight-change-preview-period-list`} disablePadding sx={{ width: "100%" }}>
          <ListItem
            key={`highlight-change-preview-period-from`}
            sx={{
              backgroundColor: setBackgroundColor(findDifference("timeStampFrom", differences)),
            }}
          >
            <ListItemAvatar>
              <Chip
                data-testid="chip-van"
                sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }}
                label={`Van`}
              />
            </ListItemAvatar>
            <ListItemText>{record.timeStampFrom}</ListItemText>
          </ListItem>
          <ListItem
            key={`highlight-change-preview-period-to`}
            sx={{
              backgroundColor: setBackgroundColor(findDifference("timeStampTo", differences)),
            }}
          >
            <ListItemAvatar>
              <Chip
                data-testid="chip-tot"
                sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }}
                label={`Tot`}
              />
            </ListItemAvatar>
            <ListItemText>{record.timeStampTo}</ListItemText>
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        key={`highlight-change-preview-timespan`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("timeSpan", differences)),
        }}
      >
        <ListItemAvatar>
          <Chip
            data-testid="chip-interval"
            sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }}
            label={`Interval`}
          />
        </ListItemAvatar>
        <ListItemText>{`Iedere ${record.timeSpan} dagen`}</ListItemText>
      </ListItem>
      <ListItem
        key={`highlight-change-preview-repeat`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("repeat", differences)),
        }}
      >
        <ListItemAvatar>
          <Chip
            data-testid="chip-herhalend"
            sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }}
            label={`Herhalend`}
          />
        </ListItemAvatar>
        <ListItemText>{(record.repeat as boolean) ? `Ja` : `Nee`}</ListItemText>
      </ListItem>
      <ListItem
        key={`highlight-change-preview-enabled`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("enabled", differences)),
        }}
      >
        <ListItemAvatar>
          <Chip
            data-testid="chip-ingeschakeld"
            sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }}
            label={`Ingeschakeld`}
          />
        </ListItemAvatar>
        <ListItemText>{(record.enabled as boolean) ? `Ja` : `Nee`}</ListItemText>
      </ListItem>
    </List>
  );
};

export default AccordionContentHighlight;
