// TODO 4098: remove this file with staging/live adaptation
import { KnowledgeSource } from "../models/KnowledgeSource";
import { createItem, deleteItem, getContainer, StorageEnvironment, updateItem } from "./StorageInteraction";

const KnowledgeSourcesDataKey = "KnowledgeSources";

/** Retrieves the knowledge sources from the context. */
export const getKnowledgeSources = async (clientId: string | null): Promise<Array<KnowledgeSource>> =>
  await getContainer(StorageEnvironment.Live, KnowledgeSourcesDataKey, clientId)
    .then((data) => data as Array<KnowledgeSource>)
    .catch((error: Error) => {
      throw new Error(`Failed to retrieve data: ${error}`);
    });

/** Updates the local and external data */
export const createKnowledgeSource = (newKnowledgeSources: KnowledgeSource): void => {
  void createItem(StorageEnvironment.Live, KnowledgeSourcesDataKey, newKnowledgeSources).catch((error: Error) => {
    throw new Error(`Failed to create source: ${error}`);
  });
};

/** Updates the external data */
export const updateKnowledgeSource = (updatedKnowledgeSource: KnowledgeSource, clientId: string | null): void => {
  void updateItem(
    StorageEnvironment.Live,
    KnowledgeSourcesDataKey,
    updatedKnowledgeSource.key,
    updatedKnowledgeSource,
    clientId,
  ).catch((error: Error) => {
    throw new Error(`Failed to update source: ${error}`);
  });
};

/** Updates the local and external data */
export const deleteKnowledgeSource = (key: string, clientId: string | null): void => {
  void deleteItem(StorageEnvironment.Live, KnowledgeSourcesDataKey, key, clientId).catch((error: Error) => {
    throw new Error(`Failed to delete source: ${error}`);
  });
};
