import React, { FC, useEffect, useState } from "react";

// Component Imports
import { List, ListItemText, ListItemButton, Badge, ListItem } from "@mui/material";
import { NavigateFunction } from "react-router-dom";

// Data Imports
import { DrawerItem, drawerItems } from "../../models/DrawerItems";

// Context Imports
import { useExternalStorageContext } from "../../contexts/ChangeTracking/ExternalStorageContext";
import { useLocalStorageContext } from "../../contexts/ChangeTracking/LocalStorageContext";

export interface DrawerContentsProps {
  navigate: NavigateFunction;
}

/**
 * Builds the list of items that will be placed in the drawer
 * @param navigate - a navigate function that allows these buttons to link through pages with react-router-dom
 * @returns  a MUIList component with ListItems children
 */
const DrawerContents: FC<DrawerContentsProps> = ({ navigate }) => {
  const [selectedTab, setSelectedTab] = useState<number>();
  const externalStorageContext = useExternalStorageContext();
  const localStorageContext = useLocalStorageContext();

  if (externalStorageContext === null || localStorageContext === null) {
    throw new Error("Contexts should not be null");
  }

  /** Set selected tab index based on current path after rerender */
  useEffect(() => {
    for (const item of drawerItems) {
      if (window.location.href.endsWith(item.urlPath)) {
        setSelectedTab(item.tabOrder);
      }
    }
  }, []);

  /**
   * Handles the click a different list item, which corresponds to switching tab.
   * @param item - The drawer item which corresponds to a tab
   */
  const handleListItemClick = (item: DrawerItem): void => {
    setSelectedTab(item.tabOrder);
    navigate(item.urlPath);
  };

  /**
   * Finds the bucket and checks for tracked changes from staging to live.
   * @param item - drawer item to check for a tracked change
   * @returns number of changes or undefined if there are no changes
   */
  const getExternalChanges = (item: DrawerItem): number | undefined => {
    const bucket = externalStorageContext.changeBuckets.find((bucket) => bucket.key === item.key);

    return bucket?.changes.length;
  };

  /**
   * Finds the bucket and checks for tracked changes from local to staging.
   * @param item - drawer item to check for a tracked change
   * @returns whether there are changes
   */
  const getLocalChanges = (item: DrawerItem): boolean => {
    const bucket = localStorageContext.changeBuckets.find((bucket) => bucket.key === item.key);

    if (bucket === undefined) return false;

    return bucket.changes.length > 0;
  };

  return (
    <List data-testid="drawer-contents" key={"page-routes-drawer"}>
      {drawerItems.map((item, index) => (
        <ListItem
          data-testid={`list-item-${item.key}`}
          key={item.key}
          dense
          disablePadding
          divider
          secondaryAction={
            getExternalChanges(item) !== undefined ? (
              <Badge data-testid={`badge-${item.key}`} badgeContent={getExternalChanges(item)} color="primary" />
            ) : null
          }
        >
          <ListItemButton
            data-testid={`list-item-button-${item.key}`}
            key={item.key}
            selected={selectedTab === index}
            onClick={() => {
              handleListItemClick(item);
            }}
            sx={{
              backgroundColor: "white",
              color: "black",
              width: "100%",
              "&.Mui-selected": {
                backgroundColor: "rgba(30, 110, 255, 0.66)",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "rgba(30, 110, 255, 1)",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "rgba(30, 110, 255, 0.33)",
              },
            }}
          >
            {getLocalChanges(item) ? (
              <ListItemText primary={`${item.tabName}*`} />
            ) : (
              <ListItemText primary={item.tabName} />
            )}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default DrawerContents;
