import axios, { AxiosRequestConfig } from "axios";

// Authorization Imports
import { getToken } from "./Auth/Auth";

// Configuration Imports
import { webApiConfig } from "./ApiConfiguration";

// Data Imports
import { NluEntity, NluIntent, NluProject, NluSuccessResponse, NluTrainingState, NluUtterance } from "../models/NLU";

// #region Clu API requests

/**
 * Get the basic project information of all projects from the backend
 */
export const getProjectsAsync = async (): Promise<Array<string>> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.get<Array<string>>(url, requestConfig);

    return response.data satisfies Array<string>;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Export the specified project.
 */
export const exportProjectAsync = async (projectName: string): Promise<NluProject> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.get<NluProject>(url, requestConfig);

    return response.data satisfies NluProject;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Import the specified project.
 */
export const importProjectAsync = async (projectName: string, project: NluProject): Promise<NluSuccessResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.put<NluSuccessResponse>(url, project, requestConfig);

    return response.data satisfies NluSuccessResponse;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Deploy the specified project.
 */
export const deployProjectAsync = async (projectName: string): Promise<NluSuccessResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Deploy`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.post<NluSuccessResponse>(url, requestConfig);

    return response.data satisfies NluSuccessResponse;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Train the specified project.
 */
export const trainProjectAsync = async (projectName: string): Promise<NluTrainingState> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Train/Start`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the POST request
    const response = await axios.get<NluTrainingState>(url, requestConfig);

    return response.data satisfies NluTrainingState;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Get the training status the specified project
 */
export const getTrainingJobsAsync = async (projectName: string): Promise<Array<NluTrainingState>> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Train`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.get<Array<NluTrainingState>>(url, requestConfig);

    return response.data satisfies Array<NluTrainingState>;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Create or update an intent in the specified project.
 */
export const createOrUpdateIntentAsync = async (
  projectName: string,
  intent: NluIntent,
): Promise<NluSuccessResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Intents`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.post<NluSuccessResponse>(url, intent, requestConfig);

    return response.data satisfies NluSuccessResponse;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Create or update an entity in the specified project.
 */
export const createOrUpdateEntityAsync = async (
  projectName: string,
  entity: NluEntity,
): Promise<NluSuccessResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Entities`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.post<NluSuccessResponse>(url, entity, requestConfig);

    return response.data satisfies NluSuccessResponse;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Create or update an utterance in the specified project.
 */
export const createOrUpdateUtteranceAsync = async (
  projectName: string,
  utterance: NluUtterance,
  previousUtterance?: string,
): Promise<NluSuccessResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Utterances`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        previousUtterance,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.post<NluSuccessResponse>(url, utterance, requestConfig);

    return response.data satisfies NluSuccessResponse;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Remove an intent from the specified project.
 */
export const removeIntentAsync = async (projectName: string, name: string): Promise<NluSuccessResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Intents`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        name,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.delete<NluSuccessResponse>(url, requestConfig);

    return response.data satisfies NluSuccessResponse;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Remove an entity from the specified project.
 */
export const removeEntityAsync = async (projectName: string, name: string): Promise<NluSuccessResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Entities`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        name,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.delete<NluSuccessResponse>(url, requestConfig);

    return response.data satisfies NluSuccessResponse;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Remove an utterance from the specified project.
 */
export const removeUtteranceAsync = async (projectName: string, utterance: string): Promise<NluSuccessResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Utterances`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        utterance,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request
    const response = await axios.delete<NluSuccessResponse>(url, requestConfig);

    return response.data satisfies NluSuccessResponse;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};
