import React, { FC, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { loginRequest } from "../../authConfig";
import { AccountCircle } from "@mui/icons-material";

interface SignInOutButtonProps {
  authenticated: boolean;
}

/**
 * Renders a sign-out button
 * @param authenticated - If the user is authenticated or not
 * @returns Sign in or Sign out button based on buttonType
 */
const SignInOutButton: FC<SignInOutButtonProps> = ({ authenticated }) => {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /**
   * Handles opening the menu
   * @param event - click event
   */
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles closing the menu
   */
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  /**
   * This handles logout
   * @param logoutType - logout type "popup" or "redirect"
   * @returns void
   */
  const handleLogout = async (): Promise<void> => {
    await instance
      .logoutRedirect({
        postLogoutRedirectUri: "/",
      })
      .catch((error) => {
        throw new Error(`Failed to complete logout procedure, reason: ${error}`);
      });
  };

  /**
   * This handles login on button click
   * @param loginType - login type "popup" or "redirect"
   * @returns void
   */
  const handleLogin = async (): Promise<void> => {
    await instance.loginRedirect(loginRequest).catch((error) => {
      throw new Error(`Failed to complete login procedure, reason: ${error}`);
    });
  };

  return (
    <>
      {!authenticated ? (
        <Button
          variant="contained"
          onClick={() => {
            void handleLogin();
          }}
        >
          Login
        </Button>
      ) : (
        <Box>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <AccountCircle fontSize="large" sx={{ color: "white" }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                void handleLogout();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Box>
      )}
    </>
  );
};

export default SignInOutButton;
