import React, { ReactElement } from "react";
import { Handle, NodeProps, Position } from "@xyflow/react";

import { DialogNodeData, DialogNode as DialogNodeType } from "./JsonToReactFlow";
import { StepTypes } from "../../../../models/StepTypes";
import styles from "../visualisationDialog.module.scss";

// MUI
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { red } from "@mui/material/colors";
import { IconButton } from "@mui/material";

/**
 * Set label and optional delete based on node type
 * @param data - dialog node to define label for
 * @returns react element with icon or label
 */
const defineLable = (data: DialogNodeData): ReactElement => {
  if (data.stepType === StepTypes.Start) {
    return <PlayArrowIcon sx={{ color: "black" }} />;
  }
  if (data.stepType === StepTypes.Finish) {
    return <SportsScoreIcon sx={{ color: "black" }} />;
  }

  return <>{data.label}</>;
};

/**
 * Component that renders the specific node
 * @param param0 - props
 * @returns node elements
 */
export const DialogNode = ({ data, selected }: NodeProps<DialogNodeType>): JSX.Element => (
  <div className={`${styles.node} ${getNodeSpecificClassName(data.stepType)}`}>
    <div className="handles targets">
      {data.targetHandles.map((handle: { id: string; className: string }) => (
        <div className={handle.className} key={handle.id}>
          <Handle key={handle.id} id={handle.id} type="target" position={Position.Top} isConnectableStart={false} />
        </div>
      ))}
    </div>
    <div className="label">{defineLable(data)}</div>
    {data.stepType !== StepTypes.Start && data.stepType !== StepTypes.Finish ? (
      <IconButton
        style={{
          position: "absolute",
          top: -12,
          right: -12,
        }}
        size="small"
        className={styles.DeleteIconButton}
        hidden={!(selected ?? false)}
        onClick={() => data.onNodesDelete(data)}
      >
        <DeleteIcon fontSize="inherit" sx={{ color: red[500] }} />
      </IconButton>
    ) : null}
    <div className="handles sources">
      {data.sourceHandles.map((handle: { id: string; className: string }) => (
        <div className={handle.className} key={handle.id}>
          <Handle key={handle.id} id={handle.id} type="source" position={Position.Bottom} />
        </div>
      ))}
    </div>
  </div>
);

/**
 * Get the correct class name for the node
 * @param type - the nodetype that needs to be checked
 * @returns the correct class name
 */
export const getNodeSpecificClassName = (type: string): string => {
  switch (type) {
    case StepTypes.Start:
      return styles.start;
    case StepTypes.Finish:
      return styles.finish;
    case StepTypes.Message:
      return styles.message;
    case StepTypes.Conditional:
      return styles.conditional;
    case StepTypes.AskWithButtons:
      return styles.askWithButtons;
    case StepTypes.YesNoDialog:
      return styles.yesNoDialog;
    case StepTypes.TextPrompt:
      return styles.textPrompt;
    case StepTypes.CreateTicketDialog:
      return styles.createTicketDialog;
    case StepTypes.Redirect:
      return styles.redirect;
    case StepTypes.LLM:
      return styles.llm;
    default:
      return "node";
  }
};

export default DialogNode;
