import React, { ChangeEvent, FC, Fragment, ReactElement, useEffect, useState } from "react";

// Component Imports
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { DEFlagIcon, FRFlagIcon, GBFlagIcon, NLFlagIcon } from "../../../Icons/FlagIcons";
import AddIcon from "@mui/icons-material/Add";
import CustomToolTip from "../../../CustomToolTip";
import AddOrEditLanguageDialog from "../../ModularEditor/AddOrEditLanguageDialog";
import { NodeSelection } from "../VisualisationDialog";

// Context imports
import { useLocalStorageContext } from "../../../../contexts/ChangeTracking/LocalStorageContext";

// Data Imports
import { getContainer, StorageEnvironment } from "../../../../API/StorageInteraction";
import { Language, LanguageText } from "../../../../models/Language";
import { ContainerNames, ShowOptionsComponentType } from "../../../../models/enums";
import { Answer } from "../../../../models/Answers";
import { ButtonOption, DialogStep } from "../../../../models/Dialogs";
import { ChangeType } from "../../../../models/ChangeTracking";

// CSS Imports
import styles from "./detailsWindows.module.scss";
import { ITSMDescriptionQuesion } from "../../../../models/ItsmModels";
import CreateAnswerDialog from "../../../Dialogs/NewEntryDialogs/CreateAnswerDialog";
import { useErrorContext } from "../../../../contexts/ErrorContext";
import { updateChangeTracking } from "../../../../contexts/ChangeTracking/UpdateChangeTracking";

interface ShowOptionsProps {
  componentType: ShowOptionsComponentType;
  optionsId?: string;
  selectedNodeData: { newNodeData: DialogStep; oldNodeData: DialogStep };
  setSelectedNodeData: (arg0: NodeSelection) => void;
}

/**
 * ShowOptions Component
 * @param selectedNodeData - The selected node data.
 * @param setSelectedNodeData - The function to set the selected node data.
 * @returns A ShowOptions component.
 */
const ShowOptions: FC<ShowOptionsProps> = ({ selectedNodeData, setSelectedNodeData, componentType, optionsId }) => {
  // Context
  const localStorageContext = useLocalStorageContext();
  if (localStorageContext === null) throw new Error("No No NOOO");

  const errorContext = useErrorContext();

  const [answers, setAnswers] = useState<Array<Answer>>([]);
  const [filteredAnswers, setFilteredAnswers] = useState<Array<Answer>>([]);
  const [options, setOptions] = useState<Array<Answer>>([]);
  const [selectedOption, setSelectedOption] = useState<Answer>();
  const [languageUsed, setLanguageUsed] = useState<string>(process.env.REACT_APP_GLOBAL_LANGUAGE!);
  const [languagesNotUsed, setLanguagesNotUsed] = useState<Array<Language>>([]);
  const [isNewLanguageDialogOpen, setIsNewLanguageDialogOpen] = useState<boolean>(false);
  const [selectedNodeOption, setSelectedNodeOption] = useState<string>();
  const [componentLabel, setComponentLabel] = useState<string>("");

  useEffect(() => {
    setSelectedNodeOption(getOptionsBasedOnComponentType(componentType));
  }, [
    selectedNodeData.newNodeData.options.selectedResponse,
    selectedNodeData.newNodeData.options.selectedQuestion,
    selectedNodeData.newNodeData.options.DescQuestions,
    selectedNodeData.newNodeData.options.buttons,
  ]);

  /**
   * This effect will set the answers and filtered answers.
   */
  useEffect(() => {
    void getContainer(StorageEnvironment.Staging, ContainerNames.Answers).then((data) => {
      const answerData = data as Array<Answer>;
      setAnswers(answerData);

      // Filter the answers based on the component type.
      const filteredAnswers: Array<Answer> = answerData.filter((value: Answer) => value.type === componentType);
      setFilteredAnswers(filteredAnswers);
    });

    // Cleanup if component closes
    return () => {
      errorContext?.clearError!();
    };
  }, [selectedNodeOption]);

  /**
   * This effect will set the options and selected option, based on the options of the selected node.
   */
  useEffect(() => {
    if (filteredAnswers === undefined) return;
    const currentOptions: Array<Answer> = [];

    filteredAnswers.forEach((answer) => {
      answer.languages.forEach((language) => {
        const index = currentOptions.findIndex((x) => x.key === answer.key);

        if (index === -1) {
          currentOptions.push({
            key: answer.key,
            languages: [{ key: language.key, value: language.value }],
            type: componentType,
          });
        } else if (index !== -1 && !currentOptions[index].languages.some((x) => x.key === language.key)) {
          currentOptions[index].languages.push({ key: language.key, value: language.value });
        }
      });
    });

    currentOptions.forEach((option) => {
      if (option.languages.length === 1) {
        if (option.languages[0].key === "NL") {
          option.languages.push({ key: "EN", value: "" });
        } else if (option.languages[0].key === "EN") {
          option.languages.push({ key: "NL", value: "" });
        }
      }
    });

    setOptions(sortOptions(currentOptions));
    setSelectedOption(currentOptions.find((x) => x.key === selectedNodeOption));
  }, [filteredAnswers]);

  /**
   * Create sort order based on answer keys
   * @param options - the array that has the be sorted
   * @returns the sorted array
   */
  const sortOptions = (options: Array<Answer>): Array<Answer> => {
    options.sort((a, b) => {
      if (a.key.toUpperCase() > b.key.toUpperCase()) {
        return 1;
      }
      if (b.key.toUpperCase() > a.key.toUpperCase()) {
        return -1;
      }

      return 0;
    });

    return options;
  };

  /**
   * Set the selected option when the selected node option changes.
   */
  useEffect(() => {
    if (options === undefined) return;

    setSelectedOption(options.find((x) => x.key === selectedNodeOption));
  }, [selectedNodeOption]);

  /**
   * Set the component label based on the received component label on mount
   */
  useEffect(() => {
    switch (componentType) {
      case ShowOptionsComponentType.Button:
        setComponentLabel("Knop");
        break;
      case ShowOptionsComponentType.DescQuestion:
      case ShowOptionsComponentType.Question:
        setComponentLabel("Vraag");
        break;
      case ShowOptionsComponentType.Message:
        setComponentLabel("Bericht");
        break;
      default:
        setComponentLabel("");
        break;
    }
  }, [componentType]);

  /**
   * Set the currently unused languages for the selected option.
   */
  useEffect(() => {
    const languageOptions: Array<Language> = [
      { key: "NL", name: "Nederlands" },
      { key: "EN", name: "Engels" },
      { key: "DE", name: "Duits" },
      { key: "FR", name: "Frans" },
    ];

    const languages: Array<Language> = [];

    languageOptions.forEach((option) => {
      if (selectedOption !== undefined) {
        if (selectedOption.languages.find((x) => x.key === option.key) === undefined) {
          languages.push(option);
        }
      }
    });

    setLanguagesNotUsed(languages);
  }, [selectedOption]);

  /**
   * Function to return the correct dialog step options based on the input type
   * @param type - The type of component question|response|descQuestions
   * @returns Options of current dialog step
   */
  const getOptionsBasedOnComponentType = (type: string): string => {
    switch (type) {
      case ShowOptionsComponentType.Question:
        return selectedNodeData.newNodeData.options.selectedQuestion;
      case ShowOptionsComponentType.Message:
        return selectedNodeData.newNodeData.options.selectedResponse;
      case ShowOptionsComponentType.DescQuestion:
        return selectedNodeData.newNodeData.options.DescQuestions.find(
          (question: ITSMDescriptionQuesion) => question.id === optionsId,
        )!.question;
      case ShowOptionsComponentType.Button:
        return selectedNodeData.newNodeData.options.buttons.find((button: ButtonOption) => button.id === optionsId)!
          .key;
      default:
        return "";
    }
  };

  /**
   * Adds a new item to the data list and updates the component state and data.
   * @param update - The new item data to be added.
   */
  const onAddItem = (update: Answer): void => {
    if (answers === undefined) return;

    // Create a copy of the current answers list and add the newly created answer
    const updatedAnswers: Array<Answer> = [...answers];
    updatedAnswers?.push(update);

    setAnswers(updatedAnswers);

    updateChangeTracking(null, update, ChangeType.New, ContainerNames.Answers, localStorageContext);

    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    switch (componentType) {
      case ShowOptionsComponentType.Question:
        updateNodeData.options.selectedQuestion = update.key;
        break;
      case ShowOptionsComponentType.Message:
        updateNodeData.options.selectedResponse = update.key;
        break;
      case ShowOptionsComponentType.DescQuestion: {
        const updatedDescQuestions = [...updateNodeData.options.DescQuestions];
        const questionIndex = updatedDescQuestions.findIndex(
          (question: ITSMDescriptionQuesion) => question.id === optionsId,
        );
        if (questionIndex !== -1) {
          updatedDescQuestions[questionIndex].question = update.key;
        }
        updateNodeData.options.DescQuestions = updatedDescQuestions;
        break;
      }
      case ShowOptionsComponentType.Button: {
        const updatedButtons = [...updateNodeData.options.buttons];
        const buttonIndex = updatedButtons.findIndex((button: ButtonOption) => button.id === optionsId);
        if (buttonIndex !== -1) {
          updatedButtons[buttonIndex].key = update.key;
        }
        updateNodeData.options.buttons = updatedButtons;
        break;
      }
      default:
        break;
    }

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  /**
   * On change function that changes the selected option
   * @param event - The event generated by changing the value of a select field.
   */
  const onChangeSelection = (event: SelectChangeEvent): void => {
    const message = options?.find((x) => x.key === event.target.value);

    if (message === undefined) return;

    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    switch (componentType) {
      case ShowOptionsComponentType.Question:
        updateNodeData.options.selectedQuestion = event.target.value;
        break;
      case ShowOptionsComponentType.Message:
        updateNodeData.options.selectedResponse = event.target.value;
        break;
      case ShowOptionsComponentType.DescQuestion: {
        const updatedDescQuestions = [...updateNodeData.options.DescQuestions];
        const questionIndex = updatedDescQuestions.findIndex(
          (question: ITSMDescriptionQuesion) => question.id === optionsId,
        );
        if (questionIndex !== -1) {
          updatedDescQuestions[questionIndex].question = event.target.value;
        }
        updateNodeData.options.DescQuestions = updatedDescQuestions;
        break;
      }
      case ShowOptionsComponentType.Button: {
        const updatedButtons = [...updateNodeData.options.buttons];
        const buttonIndex = updatedButtons.findIndex((button: ButtonOption) => button.id === optionsId);
        if (buttonIndex !== -1) {
          updatedButtons[buttonIndex].key = event.target.value;
        }
        updateNodeData.options.buttons = updatedButtons;
        break;
      }
      default:
        break;
    }

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setLanguageUsed(process.env.REACT_APP_GLOBAL_LANGUAGE!);
    setSelectedNodeData(newNodeSelection);
  };

  /**
   * Update the value of the currently selected answer
   * @param event - The event generated by changing the value of a text field.
   */
  const onChangeData = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    if (selectedOption === undefined) return;

    // Create a copy of the new item data
    const index = answers.findIndex((x) => x.key === selectedOption.key);
    const updatedAnswers = [...answers];
    const answerCopy = updatedAnswers[index];

    // Get each language in the new item data
    if (index !== -1) {
      const languageIndex = updatedAnswers[index].languages.findIndex((x) => x.key === languageUsed);

      if (languageIndex !== -1) {
        updatedAnswers[index].languages[languageIndex].value = value;
      } else {
        updatedAnswers[index].languages.push({ key: languageUsed, value });
      }
    }

    updateChangeTracking(
      answerCopy,
      updatedAnswers[index],
      ChangeType.Update,
      ContainerNames.Answers,
      localStorageContext,
    );

    const newSelectedData: Answer = { ...selectedOption };

    newSelectedData.languages.forEach((member) => {
      if (member.key.toUpperCase() === languageUsed) {
        member.value = value;
      }
    });

    setSelectedOption(newSelectedData);
  };

  /**
   * Adds a language to the entry
   * @param languageText - The language text to add to the entry
   */
  const AddLanguage = (languageText: LanguageText): void => {
    const currentKey = selectedOption!.key;
    const oldItem = answers.find((item) => item.key === currentKey);
    if (oldItem === undefined) throw new Error("impossible");

    let languages: Array<LanguageText>;
    if (oldItem.languages.some((language) => language.key === languageText.key)) {
      languages = oldItem.languages.map((language) => {
        if (language.key === languageText.key) return { ...language, value: languageText.value };

        return language;
      });
    } else {
      languages = [...oldItem.languages, languageText];
    }

    const updatedItem = { ...oldItem, languages };

    setAnswers(answers.map((item) => (item.key === updatedItem.key ? updatedItem : item)));

    updateChangeTracking(oldItem, updatedItem, ChangeType.Update, ContainerNames.Answers, localStorageContext);
  };

  /**
   * Removes a language from the entry
   * @param languageKey - The language key to remove from the entry
   */
  const removeLanguage = (languageKey: string): void => {
    const oldItem = answers.find((item) => item.key === selectedOption!.key);
    if (oldItem === undefined) throw new Error("impossible");

    const updatedItem = { ...oldItem, languages: oldItem.languages.filter((language) => language.key !== languageKey) };

    setAnswers(answers.map((item) => (item.key === updatedItem.key ? updatedItem : item)));

    updateChangeTracking(oldItem, updatedItem, ChangeType.Update, ContainerNames.Answers, localStorageContext);
  };

  /**
   * On update of a desctiption question answer
   * @param event - The event generated by changing the value of a text field.
   */
  const onUpdateDescQuestion = (
    event: ChangeEvent<HTMLInputElement>,
    fieldToUpdate: "questionHeader" | "answer",
  ): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.DescQuestions.find((question: ITSMDescriptionQuesion) => question.id === optionsId)![
      fieldToUpdate
    ] = event.target.value;

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  if (errorContext?.applicationError !== null) throw new Error(errorContext?.applicationError?.message);

  /**
   * Function that returns flag based on input language
   * @param language - input language as country code
   */
  const languageFlagSwitch = (language: string): ReactElement => {
    switch (language) {
      case "NL":
        return <NLFlagIcon />;
      case "EN":
        return <GBFlagIcon />;
      case "DE":
        return <DEFlagIcon />;
      case "FR":
        return <FRFlagIcon />;
      default:
        return <></>;
    }
  };

  return (
    <Stack gap={1}>
      {componentType === ShowOptionsComponentType.DescQuestion ? (
        <TextField
          label="Sectie naam"
          placeholder="Voer een naam voor deze sectie in"
          fullWidth
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onUpdateDescQuestion(event, "questionHeader");
          }}
          value={
            selectedNodeData.newNodeData.options.DescQuestions.find(
              (question: ITSMDescriptionQuesion) => question.id === optionsId,
            )!.questionHeader
          }
        />
      ) : null}
      {selectedOption !== undefined ? (
        <>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} alignItems={"centre"}>
              {languagesNotUsed.length > 0 ? (
                <CustomToolTip
                  title="Voeg een nieuwe taal toe"
                  child={
                    <Button
                      onClick={() => {
                        setIsNewLanguageDialogOpen(true);
                      }}
                    >
                      <AddIcon />
                    </Button>
                  }
                />
              ) : null}
              {selectedOption.languages.map((language) => (
                <Button
                  key={language.key}
                  disabled={language.key === languageUsed}
                  onClick={() => {
                    setLanguageUsed(language.key);
                  }}
                >
                  {languageFlagSwitch(language.key)}
                </Button>
              ))}
            </Stack>
            <CreateAnswerDialog
              executable={onAddItem}
              language={process.env.REACT_APP_GLOBAL_LANGUAGE!}
              typePredetermined={componentType}
            />
          </Stack>

          <FormControl fullWidth={true} className={`${styles.FormControl} ${styles.TextField}`}>
            <InputLabel id="question-select-label">{`${componentLabel} selectie`}</InputLabel>
            <Select
              labelId="questionSelectionLabel"
              id="questionSelection"
              label={`${componentLabel} selectie`}
              onChange={onChangeSelection}
              value={selectedOption?.key}
            >
              {options?.map((option: Answer) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            className={styles.TextField}
            label={
              <Fragment>
                {`${componentLabel}:`}
                {languageFlagSwitch(languageUsed)}
              </Fragment>
            }
            value={selectedOption.languages.find((x) => x.key === languageUsed)?.value}
            inputProps={{ name: "response" }}
            fullWidth={true}
            multiline
            rows={4}
            onChange={onChangeData}
          />
          {componentType === ShowOptionsComponentType.DescQuestion ? (
            <Grid item xs={12}>
              <TextField
                label="Vooraf ingevuld Antwoord"
                placeholder="Voer uw vooraf ingevulde antwoord in"
                fullWidth
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onUpdateDescQuestion(event, "answer");
                }}
                value={
                  selectedNodeData.newNodeData.options.DescQuestions.find(
                    (question: ITSMDescriptionQuesion) => question.id === optionsId,
                  )!.answer
                }
              />
            </Grid>
          ) : null}
        </>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <CreateAnswerDialog
                executable={onAddItem}
                language={process.env.REACT_APP_GLOBAL_LANGUAGE!}
                typePredetermined={componentType}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true} className={`${styles.FormControl} ${styles.TextField}`}>
              <InputLabel id="question-select-label">{componentLabel} selectie</InputLabel>
              <Select
                labelId="questionSelectionLabel"
                id="questionSelection"
                label={`${componentLabel} selectie`}
                onChange={onChangeSelection}
                value={""}
              >
                {options?.map((option: Answer) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <AddOrEditLanguageDialog
        isOpen={isNewLanguageDialogOpen}
        handleClose={() => {
          setIsNewLanguageDialogOpen(false);
        }}
        languages={languagesNotUsed}
        addLanguage={(languageText: LanguageText) => {
          AddLanguage(languageText);
        }}
        removeLanguage={(languageKey: string) => {
          removeLanguage(languageKey);
        }}
        dialogLanguage={""}
        dialogValue=""
        title=""
        isEdit={false}
      />
    </Stack>
  );
};

export default ShowOptions;
