import React, { FC, ReactElement } from "react";

// Material Imports
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  button1Text: string;
  button2Text: string;
  button1Color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  button2Color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  description?: string;
  bodyContent?: ReactElement;
  fullWidth?: boolean;
  executable: () => void;
  handleClose: () => void;
}

/**
 * A generic confirmation dialog.
 * @param open - The dialog open state.
 * @param title - The dialog title.
 * @param button1Text - The text for button 1.
 * @param button2Text - The text for button 2.
 * @param button1Color - Optional: The color of button 1.
 * @param button2Color - Optional: The color of button 2.
 * @param description - Optional: Additional description for the dialog.
 * @param bodyContent - Optional: The body content to display inside the dialog.
 * @param fullWidth - Whether to set dialog to full width
 * @param executable - Function to execute on confirmation
 * @param handleClose - Function to handle the closing operation of the confirmation dialog.
 * @returns The confirmation dialog component.
 */
const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  title,
  button1Text,
  button2Text,
  button1Color = "primary",
  button2Color = "error",
  description,
  bodyContent,
  fullWidth,
  executable,
  handleClose,
}) => (
  <Dialog data-testid="dialog" open={open} onClose={handleClose} fullWidth={fullWidth} maxWidth="lg">
    <DialogTitle data-testid="dialog-title">{title}</DialogTitle>
    <DialogContent dividers={true}>
      <DialogContentText id="dialog-description" data-testid="dialog-description">
        {description}
      </DialogContentText>
      {bodyContent}
    </DialogContent>
    <DialogActions>
      <Button
        data-testid="confirm-button"
        name="confirm"
        onClick={() => {
          executable();
          handleClose();
        }}
        variant="contained"
        color={button1Color}
      >
        {button1Text}
      </Button>
      <Button
        data-testid="cancel-button"
        name="cancel"
        onClick={() => {
          handleClose();
        }}
        variant="contained"
        color={button2Color}
      >
        {button2Text}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
