import React, { FC, useEffect, useState } from "react";
import Cookies from "js-cookie";

import { useClientContext } from "../../contexts/ClientContext";
import { AdminPanelTitles, CookieNames } from "../../models/enums";

import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

/**
 * ClientSelection Component
 * @returns A React component representing the ClientSelection
 */
const ClientSelection: FC = () => {
  const clientContext = useClientContext();

  const [clientId, setClientId] = useState<string | null>(clientContext!.clientId);

  /**
   * function to run before refresh is triggered
   */
  window.onbeforeunload = () => {
    if (clientId !== null) {
      Cookies.set(CookieNames.MULTI_TENANT_LAST_CLIENT_ID, clientId);
    }
  };

  useEffect(() => {
    if (clientContext !== null) {
      if (clientContext.clientId != null) {
        setClientId(clientContext.clientId);

        const { clientName } = clientContext.clientIdList.find((x) => x.clientId === clientContext.clientId)!;

        document.title = `${clientName} | ${AdminPanelTitles.TabTitle}`;
      } else {
        document.title = AdminPanelTitles.TabTitle;
      }
    }
  }, [clientContext]);

  /**
   * Handles the change of the client ID.
   * @param event - The event that triggered the change.
   */
  const handleClientIdChange = (event: SelectChangeEvent<string>): void => {
    if (clientContext!.updateClientId !== undefined) {
      setClientId(event.target.value);
      Cookies.set(CookieNames.MULTI_TENANT_LAST_CLIENT_ID, event.target.value);
      clientContext!.updateClientId(event.target.value);
    }
  };

  const isMultiTenant: boolean = process.env.REACT_APP_IS_MULTI_TENANT === "true";

  return (
    <div data-testid="selection-div">
      {isMultiTenant ? (
        <Select
          data-testid="clientid-selectfield"
          id="client-selection"
          size="small"
          label={clientId === null ? "Klant selecteren" : null}
          fullWidth
          value={clientId ?? ""}
          style={{ width: 200 }}
          variant="outlined"
          onChange={(event) => {
            handleClientIdChange(event);
          }}
          sx={{ backgroundColor: "white", borderRadius: 1 }}
        >
          {clientContext?.clientIdList?.map((client) => (
            <MenuItem key={client.clientId} value={client.clientId} data-testid={`${client.clientId}-client`}>
              {client.clientName}
            </MenuItem>
          ))}
        </Select>
      ) : null}
    </div>
  );
};

export default ClientSelection;
