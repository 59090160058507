export enum StepTypes {
  Start = "Start",
  Finish = "Finish",
  Message = "Message",
  Conditional = "IfStatement",
  AskWithButtons = "AskWithButtonsDialog",
  YesNoDialog = "YesNoDialog",
  TextPrompt = "TextPrompt",
  CreateTicketDialog = "CreateTicketDialog",
  LLM = "OpenAI", // deze zo laten voor nu, voor de chatbot code
  Redirect = "Redirect",
}

export enum StepTexts {
  Message = "Bericht",
  Conditional = "Conditioneel",
  AskWithButtons = "Vraag Met Knoppen",
  YesNoDialog = "Ja/Nee Vraag",
  TextPrompt = "Open Vraag",
  CreateTicket = "Ticket Aanmaken",
  LLM = "LLM",
  Redirect = "Doorverwijzen",
}
