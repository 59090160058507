import React, { FC, useEffect, useState } from "react";

// Component Imports
import ConfirmationDialog, { ConfirmationDialogProps } from "../../../Dialogs/ConfirmationDialog/ConfirmationDialog";
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import EnterTextDialog from "../../../Dialogs/ConfirmationDialog/EnterTextDialog";
import IntentGroupCard from "./IntentGroupCard/IntentGroupCard";
import AddIcon from "@mui/icons-material/Add";
import ProjectListDropdown from "../Apps/ProjectListDropdown";

// Data Imports
import { IntentGroup } from "../../../../models/IntentGroups";

// Context Imports
import { useNLUContext } from "../../../../contexts/NLU/NLUContext";
import { useIntentGroupContext } from "../../../../contexts/NLU/IntentGroupContext";

// Styling
import "./List.css";

/**
 * IntentsView Component
 * @param appId - The id of the app.
 * @param intentsList - The list of intents.
 * @returns A React component representing the IntentsView to display and edit the intents.
 */
const IntentGroupList: FC = () => {
  // Context
  const CLUContext = useNLUContext();
  const IntentGroupContext = useIntentGroupContext();

  // State
  const [training, setTraining] = useState<boolean>(false);

  // Popup window states.
  const [showCreateNewGroupWindow, setShowCreateNewGroupWindow] = useState<boolean>(false);
  const [showCreateNewIntentWindow, setShowCreateNewIntentWindow] = useState<boolean>(false);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<ConfirmationDialogProps>({
    /**
     * @returns void
     */
    executable: () => {},
    title: "",
    button1Text: "",
    button2Text: "",
    description: "",
    open: false,

    /**
     * @returns void
     */
    handleClose: () => {},
  });

  /** Check every 20 seconds if CLU project is being trained */
  useEffect(() => {
    // Interval between calls in miliseconds
    const intervalTime: number = 20000;

    const interval = setInterval(() => {
      checkIfTraining().catch((err) => {
        throw new Error(err);
      });
    }, intervalTime);

    /** Get training status every few seconds */
    const checkIfTraining = async (): Promise<void> => {
      if (CLUContext !== null) {
        const isTraining = await CLUContext.getTrainingStatusOnCallInterval!(false);
        setTraining(isTraining);
      }
    };

    return () => {
      clearInterval(interval);
    };
  });

  /**
   * Handles the add of an intent group
   * @param name - The name of the new group.
   * @returns Promise<void>
   */
  const handleAddIntentGroup = async (name: string): Promise<void> => {
    if (IntentGroupContext === null) return;

    // Create an update for the context.
    const newGroup: IntentGroup = {
      key: `intent-groups-group-${name}`,
      name,
      intents: [],
    };

    // Create an update.
    const update: Array<IntentGroup> = [...IntentGroupContext.intentGroups, newGroup];
    await IntentGroupContext.setIntentGroups!(update);
  };

  /**
   * Handles the add of an intent
   * @param name - The name of the new intent.
   * @returns Promise<void>
   */
  const handleAddIntent = (name: string): void => {
    if (CLUContext !== null) {
      void CLUContext.createOrUpdateIntent!(name);
    }
  };

  /**
   * Closes the confirmation dialog
   * @returns void
   */
  const handleCloseConfirmationDialog = (): void => {
    setConfirmationDialogProps({
      /**
       * @returns void
       */
      executable: () => {},
      title: "",
      button1Text: "",
      button2Text: "",
      description: "",
      open: false,

      /**
       * @returns void
       */
      handleClose: () => {},
    });
  };

  /**
   * Train the current CLU app
   */
  const handleTrainingRequest = async (): Promise<void> => {
    if (CLUContext === null) return;
    setTraining(true);
    await CLUContext.trainSelectedProject!();
    const isTraining = await CLUContext.getTrainingStatus!();
    setTraining(isTraining);
  };

  if (IntentGroupContext === null) {
    return <></>;
  } else {
    return (
      <>
        <EnterTextDialog
          isOpen={showCreateNewGroupWindow}
          title="Nieuwe intent groep toevoegen"
          description="Geef een naam op voor de nieuwe intent groep"
          handleClose={() => {
            setShowCreateNewGroupWindow(false);
          }}
          executable={(value: string) => {
            handleAddIntentGroup(value).catch((error) => {
              throw new Error(`Failed to add new intent group: ${error}`);
            });
          }}
          button1Text="Toevoegen"
          button2Text="Annuleren"
          placeholder="Nieuwe intent groep naam"
        />
        <EnterTextDialog
          isOpen={showCreateNewIntentWindow}
          title="Nieuwe intent toevoegen"
          description={`Geef een  nieuwe intent op, deze wordt automatisch aan "Overige" toegevoegd`}
          handleClose={() => {
            setShowCreateNewIntentWindow(false);
          }}
          executable={(value: string) => {
            handleAddIntent(value);
          }}
          button1Text="Toevoegen"
          button2Text="Annuleren"
          placeholder="Nieuwe intent"
        />
        <ConfirmationDialog {...confirmationDialogProps} />
        <Stack
          marginTop={"10px"}
          direction="column"
          spacing={0.5}
          divider={<Divider variant="middle" flexItem />}
          width={"100%"}
          height={"100%"}
        >
          <Grid
            container
            spacing={1}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {CLUContext !== null && (
              <Grid item xs={7}>
                <ProjectListDropdown projects={CLUContext.projects} />
              </Grid>
            )}
            <Grid item xs={3}>
              <Button
                onClick={() => {
                  setShowCreateNewIntentWindow(true);
                }}
                sx={{ width: "100%", height: "45px" }}
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                variant="contained"
              >
                Intent
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                sx={{ width: "100%", height: "45px" }}
                size={"small"}
                variant="contained"
                color={"primary"}
                onClick={training ? undefined : handleTrainingRequest}
                style={{ float: "right" }}
                disabled={training}
              >
                {training ? `Training` : `Train`}
              </Button>
            </Grid>
          </Grid>

          <List sx={{ width: "100%", height: "100%", marginTop: "0px", overflow: "auto" }} className="List">
            {IntentGroupContext.intentGroups.length > 0 &&
              IntentGroupContext.intentGroups.map((group) => (
                <ListItem key={`intent-group-${group.key}`}>
                  <IntentGroupCard
                    handleCloseConfirmationDialog={handleCloseConfirmationDialog}
                    intentGroup={group}
                    setConfirmationDialogProps={setConfirmationDialogProps}
                  />
                </ListItem>
              ))}
            <Divider />
            <ListItemButton
              onClick={() => {
                setShowCreateNewGroupWindow(true);
              }}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Groep Toevoegen</ListItemText>
            </ListItemButton>
          </List>
        </Stack>
      </>
    );
  }
};

export default IntentGroupList;
