import { StepTypes } from "../../../../models/StepTypes";
import { ButtonOption, DialogStep, DialogVersion } from "../../../../models/Dialogs";
import { Edge, MarkerType, Node } from "@xyflow/react";

export type DialogNodeData = {
  id: string;
  label: string;
  sourceHandles: Array<{ id: string; className: string }>;
  targetHandles: Array<{ id: string; className: string }>;
  stepType: string;
  selectable: boolean;
  onNodesDelete: any;
};

export type DialogEdgeData = {
  onEdgeDelete: any;
};

export type DialogNode = Node<DialogNodeData, "elk">;
export type DialogEdge = Edge<DialogEdgeData, "CIM">;

/**
 * Function that fills the nodes used
 * @param dialogVersion - the specific dialog version
 * @param onNodesDelete - the onNodesDelete function
 * @returns - Array of nodes
 */
export const fillInitialNodes = (dialogVersion: DialogVersion | undefined, onNodesDelete: any): Array<DialogNode> => {
  const nodes: Array<DialogNode> = [];

  dialogVersion!.steps.forEach((step: DialogStep) => {
    const commonProps = {
      id: step.id,
      data: {
        id: step.id,
        label: step.name,
        sourceHandles: fillSourceHandles(step),
        targetHandles: [{ id: `${step.id}_in`, className: "port" }],
        stepType: step.type,
        selectable: true,
        onNodesDelete,
      },
      position: { x: 0, y: 0 },
    };

    switch (step.type) {
      case StepTypes.Start:
        nodes.push({
          ...commonProps,
          data: {
            id: step.id,
            label: StepTypes.Start,
            sourceHandles: commonProps.data.sourceHandles,
            targetHandles: [],
            stepType: step.type,
            selectable: false,
            onNodesDelete,
          },
          type: "elk",
        });
        break;
      case StepTypes.Finish:
        nodes.push({
          ...commonProps,
          data: {
            id: step.id,
            label: StepTypes.Finish,
            sourceHandles: [],
            targetHandles: commonProps.data.targetHandles,
            stepType: step.type,
            selectable: false,
            onNodesDelete,
          },
          type: "elk",
        });
        break;
      default:
        nodes.push({
          ...commonProps,
          type: "elk",
        });
        break;
    }
  });

  return nodes;
};

/**
 * Fills the handles for a node
 * @param step - the specific step that needs handles
 * @returns - array of handles
 */
export const fillSourceHandles = (step: DialogStep): Array<{ id: string; className: string }> => {
  const ports: Array<{ id: string; className: string }> = [];

  /**
   * Adds a port/handle to the array
   * @param id - is of the port/handle
   * @param className - the classname of the port/handle
   */
  const addPort = (id: string, className: string): void => {
    ports.push({ id, className });
  };

  switch (step.type) {
    case StepTypes.Start:
      addPort(`${step.id}_out`, "port");
      break;

    case StepTypes.AskWithButtons:
    case StepTypes.YesNoDialog:
      step.options.buttons.forEach((option: ButtonOption) => {
        const className = `${"port"} ${
          option.key.toLowerCase() === "else" || option.key.toLowerCase() === "no" ? "elsePort" : "ifPort"
        }`;
        addPort(option.id, className);
      });
      break;

    case StepTypes.Conditional:
      step.options.ifs.forEach((option: ButtonOption) => {
        const className = `${"port"} ${
          option.key.toLowerCase() === "else" || option.key.toLowerCase() === "no" ? "elsePort" : "ifPort"
        }`;
        addPort(option.id, className);
      });
      break;

    default:
      addPort(`${step.id}_out`, "port");
      break;
  }

  return ports;
};

/**
 * Function that fills the edges
 * @param dialogVersion - the dialog version that is used
 * @param onEdgeDelete - the function for edge deletion
 * @returns - Array of edges
 */
export const fillInitialEdges = (dialogVersion: DialogVersion | undefined, onEdgeDelete: any): Array<Edge> => {
  const edges: Array<Edge> = [];

  dialogVersion!.steps.forEach((step: DialogStep) => {
    switch (step.type) {
      case StepTypes.AskWithButtons:
      case StepTypes.YesNoDialog:
        step.options.buttons.forEach((option: ButtonOption) => {
          if (option.nextStep !== "" && option.nextStep !== undefined && JSON.stringify(option.nextStep) !== "{}") {
            edges.push({
              id: `${option.id}---${option.nextStep}`,
              source: step.id,
              sourceHandle: option.id,
              target: option.nextStep,
              targetHandle: `${option.nextStep}_in`,
              data: { onEdgeDelete },
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
              type: "CIM",
            });
          }
        });
        break;

      case StepTypes.Conditional:
        step.options.ifs.forEach((option: ButtonOption) => {
          if (option.nextStep !== "" && option.nextStep !== undefined && JSON.stringify(option.nextStep) !== "{}") {
            edges.push({
              id: `${option.id}---${option.nextStep}`,
              source: step.id,
              sourceHandle: option.id,
              target: option.nextStep,
              targetHandle: `${option.nextStep}_in`,
              data: { onEdgeDelete },
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
              type: "CIM",
            });
          }
        });
        break;

      default:
        if (step.nextStep !== "" && step.nextStep !== undefined && JSON.stringify(step.nextStep) !== "{}") {
          edges.push({
            id: `${step.id}---${step.nextStep}`,
            source: step.id,
            sourceHandle: `${step.id}_out`,
            target: step.nextStep,
            targetHandle: `${step.nextStep}_in`,
            data: { onEdgeDelete },
            markerEnd: {
              type: MarkerType.ArrowClosed,
            },
            type: "CIM",
          });
        }
        break;
    }
  });

  return edges;
};
