import { webApiConfig } from "./ApiConfiguration";
import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "./Auth/Auth";
import { Client } from "../models/Client";

/**
 * Fetches the list of clients from the API.
 * @returns The list of clients.
 */
export const getClients = async (): Promise<Array<Client>> => {
  const token = await getToken();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
    },
    signal: new AbortController().signal,
  };

  const response = await axios.get(`${webApiConfig.endpointUrl}/Clients`, requestConfig);

  if (response.data.length === 0) {
    throw new Error("Multi-tenant instance should have clients, but zero were found");
  }

  return response.data as Array<Client>;
};
