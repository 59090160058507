// Enum knowledge source types (matches knowledge source app)
export enum KnowledgeSourceType {
  AtlassianWiki = "AtlassianWiki",
  AzureDevOpsWiki = "AzureDevOpsWiki",
  DocumentUpload = "DocumentUpload",
  GoogleDrive = "GoogleDrive",
  SharePoint = "SharePoint",
  UrlScraper = "UrlScraper",
}

// Enum for source status
export enum SynchronizeStatus {
  New = "New",
  Edit = "Edit",
  Sync = "Sync",
  Fail = "Fail",
  Delete = "Delete",
}

// Type specific config for url types
export type UrlConfig = {
  url: string;
  recursive: boolean;
};

// Type specific config for document types
export type DocumentConfig = {
  folderId: string;
  filenames: Array<string>;
};

// Default document config
export const documentConfig: DocumentConfig = {
  folderId: "",
  filenames: [],
};

// Default url config
export const urlConfig: UrlConfig = {
  url: "",
  recursive: false,
};

// Knowledge source base properties
export type KnowledgeSourceBase = {
  key: string;
  clientId: string | null;
  name: string;
  description: string;
  status: SynchronizeStatus;
  lastSyncSuccess: string;
  lastSyncRequest: string;
  lastModified: string;
};

// Knowledge source type specific properties
export type KnowledgeSource =
  | (KnowledgeSourceBase & { type: ""; config: Record<string, never> })
  | (KnowledgeSourceBase & {
      type:
        | KnowledgeSourceType.AtlassianWiki
        | KnowledgeSourceType.AzureDevOpsWiki
        | KnowledgeSourceType.GoogleDrive
        | KnowledgeSourceType.SharePoint
        | KnowledgeSourceType.UrlScraper;
      config: UrlConfig;
    })
  | (KnowledgeSourceBase & { type: KnowledgeSourceType.DocumentUpload; config: DocumentConfig });

// Message if there has not been a (successful) synchronization
export const NotSyncedMsg = "n.v.t.";

// Default knowledge source
export const knowledgeSource: KnowledgeSource = {
  key: "",
  clientId: null,
  name: "",
  description: "",
  type: "",
  config: {},
  status: SynchronizeStatus.New,
  lastSyncSuccess: NotSyncedMsg,
  lastSyncRequest: NotSyncedMsg,
  lastModified: "",
};

// Knowledge source type display value
export const knowledgeSourceTypeMessage = [
  { key: KnowledgeSourceType.AtlassianWiki, value: "Atlassian Wiki" },
  { key: KnowledgeSourceType.AzureDevOpsWiki, value: "Azure DevOps Wiki" },
  { key: KnowledgeSourceType.DocumentUpload, value: "Document Upload" },
  { key: KnowledgeSourceType.GoogleDrive, value: "Google Drive" },
  { key: KnowledgeSourceType.SharePoint, value: "SharePoint" },
  { key: KnowledgeSourceType.UrlScraper, value: "Website Scraper" },
];

// File types allowed to be uploaded
export const allowedFileExtensions = ["txt", "pdf", "docx"];
