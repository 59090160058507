import moment from "moment";
import { CursedType } from "../../components/Editors/ModularEditor/ModularEditor";
import { ChangeType, TrackedChange } from "../../models/ChangeTracking";
import { localStorageContextAttributes } from "./LocalStorageContext";
import { Dialog } from "../../models/Dialogs";

/** Update change tracking */
export const updateChangeTracking = (
  oldValue: CursedType | Dialog | null,
  newValue: CursedType | Dialog | null,
  changeType: ChangeType,
  containerName: string,
  context: localStorageContextAttributes,
): void => {
  const value = newValue ?? oldValue;
  if (value === null) throw new Error("Either new or old value should not be null");

  const key: string = context.createKey!(containerName, value.key);

  const trackedChange: TrackedChange = {
    key,
    containerName,
    oldValue,
    newValue,
    lastModified: moment().toDate(),
    changeType,
    live: false,
    displayOrder: Object.keys(value),
  };

  switch (changeType) {
    case ChangeType.New:
      context.trackChange!(trackedChange);
      break;
    case ChangeType.Delete:
      // If the change exists, a newly made item is deleted,
      // otherwise a previously existing item is deleted.
      if (context.trackedChangeExists!(trackedChange)) {
        context.updateChange!(trackedChange);
      } else {
        context.trackChange!(trackedChange);
      }
      break;
    case ChangeType.Update: {
      // Retrieve a previous update for this item and modify it, or create a new change if did not exist.
      const trackedChangeResult = context.getChangeByKey!(key, containerName);
      if (trackedChangeResult === undefined) {
        context.trackChange!(trackedChange);
      } else {
        context.updateChange!(trackedChange);
      }
    }
  }
};
