import { webApiConfig } from "./ApiConfiguration";
import { TopDeskItem, TopDeskSubcategory } from "../models/ItsmModels";
import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "./Auth/Auth";

/**
 * Fetches the TOPdesk group incidents
 * @returns The TOPdesk group incidents.
 */
export const getTopdeskGroupIncidents = async (): Promise<Array<any>> => {
  try {
    const response = await fetchTopDeskArray(`${webApiConfig.endpointUrl}/TopDesk/groupincidents`);

    if (response.status !== 200) {
      if (response.status === 204) {
        return [];
      }

      throw new Error(`Request failed: ${response.status} - ${response.statusText}`);
    }

    return response.data;
  } catch (error) {
    console.error(error);

    return [];
  }
};

export type TopDeskApiData = {
  callTypes: Array<TopDeskItem>;
  statuses: Array<TopDeskItem>;
  categories: Array<TopDeskItem>;
  entryTypes: Array<TopDeskItem>;
  impacts: Array<TopDeskItem>;
  priorities: Array<TopDeskItem>;
  subcategories: Array<TopDeskSubcategory>;
  urgencies: Array<TopDeskItem>;
};

/**
 * Loads the TOPdesk data
 * @returns The TOPdesk data.
 */
export const loadTopDeskData = async (): Promise<TopDeskApiData> => {
  const url = `${webApiConfig.endpointUrl}/TopDesk/incidents/parameters`;

  const list = await fetchTopDeskArray(url);

  const callTypes: Array<TopDeskItem> = getSpecificIncidentParameters<TopDeskItem>(list.data, "call_types");
  const statuses: Array<TopDeskItem> = getSpecificIncidentParameters<TopDeskItem>(list.data, "statuses");
  const categories: Array<TopDeskItem> = getSpecificIncidentParameters<TopDeskItem>(list.data, "categories");
  const entryTypes: Array<TopDeskItem> = getSpecificIncidentParameters<TopDeskItem>(list.data, "entry_types");
  const impacts: Array<TopDeskItem> = getSpecificIncidentParameters<TopDeskItem>(list.data, "impacts");
  const priorities: Array<TopDeskItem> = getSpecificIncidentParameters<TopDeskItem>(list.data, "priorities");
  const subcategories: Array<TopDeskSubcategory> = getSpecificIncidentParameters<TopDeskSubcategory>(
    list.data,
    "subcategories",
  );
  const urgencies: Array<TopDeskItem> = getSpecificIncidentParameters<TopDeskItem>(list.data, "urgencies");

  return {
    callTypes,
    statuses,
    categories,
    entryTypes,
    impacts,
    priorities,
    subcategories,
    urgencies,
  };
};

/**
 * Fetches objects from TOPdesk and returns the JSON array
 * @param url - The URL to fetch the objects from.
 * @param token - The TOPdesk authentication token.
 * @returns The JSON array.
 */
const fetchTopDeskArray = async (url: string): Promise<any> => {
  const requestConfig: AxiosRequestConfig = {
    headers: await generateTopDeskHeaders(),
    signal: new AbortController().signal,
  };

  const response = await axios.get(url, requestConfig);

  if (response.status !== 200) {
    if (response.status === 204) {
      return [];
    }

    throw new Error(`Request failed: ${response.status} - ${response.statusText}`);
  }

  return response;
};

/**
 * Get the correct data from all incident parameters
 */
const getSpecificIncidentParameters = <TResponse>(data: Array<any>, type: string): Array<TResponse> =>
  data.find((x: { key: string }) => x.key === type).value;

/**
 * Generates the TOPdesk headers
 * @param token - The TOPdesk authentication token.
 * @returns The TOPdesk headers.
 */
const generateTopDeskHeaders = async (): Promise<object> => {
  const token = await getToken();

  return {
    Authorization: token,
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
  };
};
