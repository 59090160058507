import React, { FC, useEffect, useState } from "react";

// Component Imports
import {
  Badge,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";
import { IntentGroup } from "../../../../../models/IntentGroups";
import IntentList from "./IntentList";
import { ConfirmationDialogProps } from "../../../../Dialogs/ConfirmationDialog/ConfirmationDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

// Context Imports
import { useNLUContext } from "../../../../../contexts/NLU/NLUContext";
import { useDrop } from "react-dnd";
import { useIntentGroupContext } from "../../../../../contexts/NLU/IntentGroupContext";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface IntentGroupCardProps {
  handleCloseConfirmationDialog: () => void;
  intentGroup: IntentGroup;
  setConfirmationDialogProps: (confirmationDialogProps: ConfirmationDialogProps) => void;
}

/**
 * IntentGroupCard Component
 * @param handleCloseConfirmationDialog - The function to handle the closing of the confirmation dialog.
 * @param intentGroup - The intent group.
 * @param removeIntentGroup - The function to remove the intent group.
 * @param setConfirmationDialogProps - The function to set the confirmation dialog props.
 * @returns A React component representing the IntentGroupCard to display and edit the intents.
 */
const IntentGroupCard: FC<IntentGroupCardProps> = ({
  handleCloseConfirmationDialog,
  intentGroup,
  setConfirmationDialogProps,
}: IntentGroupCardProps) => {
  // Context
  const CLUContext = useNLUContext();
  const IntentGroupContext = useIntentGroupContext();

  // state for expansion of the card.
  const [expanded, setExpanded] = useState<boolean>(true);

  // State
  const [, drop] = useDrop(
    () => ({
      accept: "LISTITEM",

      /**
       * @returns void
       */
      drop: () => intentGroup,

      /**
       * @param monitor - The monitor.
       */
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    ["move"],
  );

  useEffect(() => {
    if (CLUContext === null || IntentGroupContext === null) return;
    if (intentGroup.key === IntentGroupContext.intentGroupOtherKey && CLUContext.selectedIntent === null) {
      const firstIntent = intentGroup.intents[0];

      CLUContext.setSelectedIntent!(firstIntent);
    }
  }, []);

  /**
   * Handle the click of the expand button.
   */
  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  /**
   * Handle the deletion of an intent group.
   */
  const handleDeleteIntentGroup = (): void => {
    setConfirmationDialogProps({
      /**
       * @returns void
       */
      executable: () => {
        void removeGroup(intentGroup.key);
      },
      title: `Verwijderen intent groep`,
      button1Text: "Bevestigen",
      button2Text: "Annuleren",
      description: `Weet je zeker dat je intent groep ${intentGroup.name} wilt verwijderen?`,
      open: true,

      /**
       * @returns void
       */
      handleClose: () => {
        handleCloseConfirmationDialog();
      },
    });
  };

  /**
   * Handles the remove of an intent group
   * @param groupId - The id of the group.
   * @returns Promise<void>
   */
  const removeGroup = async (key: string): Promise<void> => {
    if (IntentGroupContext === null) return;

    // Filter out the targeted group from the context.
    const filtered = IntentGroupContext.intentGroups.filter((group) => group.key !== key);
    await IntentGroupContext.setIntentGroups!(filtered);
  };

  if (IntentGroupContext === null) {
    return <></>;
  } else {
    return (
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          borderColor: "white",
          backgroundColor: "#2979ff",
        }}
        ref={drop}
      >
        <CardHeader
          title={intentGroup.name}
          avatar={
            <Badge
              badgeContent={intentGroup.intents !== null ? intentGroup.intents.length : 0}
              color={intentGroup.intents.length < 1 ? "warning" : "success"}
              sx={{ marginLeft: "10px" }}
              showZero
            />
          }
          sx={{ color: "white" }}
        />
        <CardActions disableSpacing>
          {intentGroup.key !== IntentGroupContext?.intentGroupOtherKey && (
            <IconButton
              disabled={intentGroup.key === IntentGroupContext?.intentGroupOtherKey}
              onClick={handleDeleteIntentGroup}
            >
              <DeleteIcon sx={{ color: "white" }} />
            </IconButton>
          )}
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon sx={{ color: "white" }} />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout={"auto"} unmountOnExit>
          <CardContent>
            <IntentList
              intentGroup={intentGroup}
              handleCloseConfirmationDialog={handleCloseConfirmationDialog}
              setConfirmationDialogProps={setConfirmationDialogProps}
            />
          </CardContent>
        </Collapse>
      </Card>
    );
  }
};

export default IntentGroupCard;
