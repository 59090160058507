import React, { FC, useState } from "react";

// Component Imports
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Data Imports
import { TrackedChange } from "../../models/ChangeTracking";

// Factory Import
import { AccordionContentFactoryStarter } from "./AccordionComponents/AccordionContentFactory";

interface SaveAccordionProps {
  change: TrackedChange;
}

/**
 * Committable change item Component
 * @param change - The change to display.
 * @param allChecked - The function to handle the checkbox change by the parent's checkbox.
 * @returns A React component representing the change to display data for staging to production selection
 */
const SaveAccordion: FC<SaveAccordionProps> = ({ change }) => {
  // State
  const [expanded, setExpanded] = useState<boolean>(false);

  /**
   * Handle the expansion of the accordion
   */
  const handleExpansion = (): void => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      key={`panel-${change.key}`}
      slotProps={{
        transition: { unmountOnExit: true },
      }}
      sx={{ width: "100%" }}
    >
      <AccordionSummary
        data-testid="accordion-summary"
        expandIcon={<ExpandMore />}
        id={`panel-${change.key}-header`}
        onClick={handleExpansion}
      >
        {expanded ? "Veranderingen niet meer tonen" : "Veranderingen tonen"}
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: "100%" }}>{AccordionContentFactoryStarter(change)}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SaveAccordion;
