import React, { FC, useState, useEffect, useRef } from "react";

// Material Imports
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// Context Imports
import { useNLUContext } from "../../../../contexts/NLU/NLUContext";
import { NluIntent } from "../../../../models/NLU";

interface CreateNewIntentProps {
  executable?: (update: any) => void;
}

/**
 * A functional component representing the 'CreateHighlightDialog'.
 * @param executable - Function to execute when the dialog content is submitted.
 * @param language - The language for the 'CreateHighlightDialog' component.
 * @returns JSX element representing the 'CreateHighlightDialog'.
 */
const CreateNewIntent: FC<CreateNewIntentProps> = ({ executable }) => {
  const [newIntent, setNewIntent] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");

  // Context.
  const CLUContext = useNLUContext();

  // Textfield States.
  const [newIntentError, setNewIntentError] = useState<boolean>(false);
  const [newIntentErrorText, setNewIntentErrorText] = useState<string>("");

  /**
   * Handles opening the dialog with the specified scroll type.
   * @param scrollType - The scroll type for the dialog (e.g., 'paper', 'body', 'paperScrollBody', etc.).
   */
  const handleOpen = (scrollType: DialogProps["scroll"]) => () => {
    setScroll(scrollType);
    setOpen(true);
  };

  /**
   * Handles closing the dialog and resetting the 'newIntent' state to its initial values.
   */
  const handleClose = (): void => {
    setNewIntent("");
    setOpen(false);
  };

  /**
   * Ref to the description element for accessibility purposes.
   */
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    } else {
      return () => {
        setNewIntent("");
      };
    }
  }, [open]);

  /**
   * Handles the change event when the input values are changed.
   * Updates the corresponding properties in the 'newHighlight' state based on the event target's 'name'.
   * @param event - The React change event representing the change in the input value.
   */
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    if (name === "new-intent") {
      setNewIntent(value);
    }
  };

  /**
   * Handles the click event and what should happen for adding a new intent.
   * @param event - The React mouse event representing a click on the component.
   */
  const onConfirm = async (): Promise<void> => {
    if (CLUContext !== null) {
      const _newIntent = newIntent;

      if (CLUContext.selectedProject === null) return;

      if (
        CLUContext.selectedProject.assets.intents.some(
          (intent: NluIntent) => intent.name.toLowerCase() === _newIntent.toLowerCase(),
        )
      ) {
        setNewIntentError(true);
        setNewIntentErrorText("Deze intent bestaat al.");
      } else if (["", null, undefined].includes(_newIntent)) {
        setNewIntentError(true);
        setNewIntentErrorText("Mag niet leeg zijn.");
      } else {
        // Add new intent and set it for the current dialog.
        const createdIntent = await CLUContext.addIntents!(_newIntent, true);
        executable!(createdIntent);

        // Clean up the dialog.
        setNewIntent("");
        setNewIntentError(false);
        setNewIntentErrorText("");

        // Close the Dialog.
        handleClose();
      }
    }
  };

  if (CLUContext === null) {
    return <></>;
  } else {
    return (
      <>
        <Button variant={"contained"} onClick={handleOpen("paper")}>
          Nieuwe Intent
        </Button>
        <Dialog open={open} onClose={handleClose} scroll={scroll}>
          <DialogTitle>Nieuwe Intent</DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText id="new-dialog-description" borderBottom={"1px solid #dee2e6"} marginBottom={"10px"}>
              Voeg een nieuwe intent toe aan de chatbot vanuit de dialoog editing tool. Deze intent wordt meteen
              gekoppeld aan de huidig geselecteerde dialoog, dus uw eerdere selectie wordt mogelijk overschreven.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="input-intent-wBtn"
                  label="Nieuw Intent"
                  variant="standard"
                  fullWidth
                  value={newIntent}
                  onChange={onInputChange}
                  name="new-intent"
                  error={newIntentError}
                  helperText={newIntentErrorText}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ color: "green" }}
              onClick={() => {
                void onConfirm();
              }}
            >
              Opslaan
            </Button>
            <Button sx={{ color: "red" }} onClick={handleClose}>
              Annuleren
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};

export default CreateNewIntent;
