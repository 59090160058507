import React, { createContext, Dispatch, FC, useContext, useMemo, useReducer } from "react";

const ErrorContext = createContext<ErrorContextAttributes | null>(null);

const ErrorDispatchContext = createContext<Dispatch<ErrorReducerAction> | null>(null);

const initialErrorContext: ErrorContextAttributes = {
  applicationError: null,
};

export enum ErrorActionType {
  setError,
  clearError,
}

interface ErrorContextProviderProps {
  children: React.ReactNode;
}

export type ErrorContextAttributes = {
  applicationError: Error | null;
  createError?: (error: Error) => void;
  clearError?: () => void;
};

/**
 * Provides the error context
 * @param children - The children of the component.
 * @returns The error context.
 */
export const ErrorContextProvider: FC<ErrorContextProviderProps> = ({ children }) => {
  const [context, dispatch] = useReducer(errorReducer, initialErrorContext);

  /**
   * Creates a new error to be dispatched
   */
  const createError = (error: Error): void => {
    const action: ErrorReducerAction = {
      type: ErrorActionType.setError,
      payload: error,
    };
    dispatch(action);
  };

  /**
   * Clears current error
   */
  const clearError = (): void => {
    const action: ErrorReducerAction = {
      type: ErrorActionType.clearError,
      payload: null,
    };
    dispatch(action);
  };

  const _context: ErrorContextAttributes = useMemo(
    () => ({
      applicationError: context.applicationError,
      createError,
      clearError,
    }),
    [context.applicationError],
  );

  return (
    <ErrorContext.Provider value={_context}>
      <ErrorDispatchContext.Provider value={dispatch}>{children}</ErrorDispatchContext.Provider>
    </ErrorContext.Provider>
  );
};

/**
 * Error context function
 */
export const useErrorContext = (): ErrorContextAttributes | null => useContext(ErrorContext);

/**
 * Error dispatch context function
 */
export const useErrorDispatchContext = (): Dispatch<ErrorReducerAction> | null => useContext(ErrorDispatchContext);

/**
 * Reducer action with type and payload
 */
export type ErrorReducerAction = {
  type: ErrorActionType;
  payload: Error | null;
};

/**
 * The error reducer which sets an error
 */
export const errorReducer = (context: ErrorContextAttributes, action: ErrorReducerAction): ErrorContextAttributes => {
  switch (action.type) {
    case ErrorActionType.setError:
      return { ...context, applicationError: action.payload };
    case ErrorActionType.clearError:
      return { ...context, applicationError: null };
  }
};
