import React, { FC } from "react";

// Component Imports
import { Box, CircularProgress, List } from "@mui/material";
import SaveList from "./SaveList";

// Context Imports
import { localStorageContextAttributes } from "../../contexts/ChangeTracking/LocalStorageContext";
import { externalStorageContextAttributes } from "../../contexts/ChangeTracking/ExternalStorageContext";

// Data Imports
import { ChangeBucket } from "../../models/ChangeTracking";
export interface ChangesToSaveProps {
  context: localStorageContextAttributes | externalStorageContextAttributes | null;
}

/**
 * Changes to save component
 * @returns A React component representing the data to display and edit what values to save based on a given context.
 */
const ChangesToSave: FC<ChangesToSaveProps> = ({ context }) => {
  if (context !== null) {
    return (
      <>
        <Box data-testid="title" component="span">
          Weet je zeker dat je de wijzigingen wil opslaan?
        </Box>
        <List data-testid="save-list" sx={{ bgcolor: "background.paper" }}>
          {context.changeBuckets.map((bucket: ChangeBucket) => {
            if (bucket.key === "intents" || bucket.changes.length <= 0) return null;

            return (
              <SaveList
                data-testid={`list-${bucket.key}`}
                key={`list-${bucket.key}`}
                changeBucket={bucket}
                context={context}
              />
            );
          })}
        </List>
      </>
    );
  } else {
    return <CircularProgress data-testid="loading-indicator" />;
  }
};

export default ChangesToSave;
